import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { allRoutes } from "../../../assets/routes/allRoutes";
import ImageWithBasePath from "../../../assets/images/ImageWithBasicPath";
import * as Yup from "yup";
import Select from "react-select";
import axios from "axios";
import {
  companyName,
  optionssymbol,
  priorityList,
  salestypelist,
  socialMedia,
  status,
  ascendingandDecending,
  statusList,
  languageOptions,
} from "./selectoption";
import DatePicker from "react-datepicker";
import DefaultEditor from "react-simple-wysiwyg";
import { TagsInput } from "react-tag-input-component";
import CollapseHeader from "../../../assets/common/CollapseHeader";
import { SelectWithImage } from "../../../assets/common/selectWithImage";
import useTheme from "../../../hooks/useTheme";
import MainServices from "../../../services/MainServices";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import AsyncSelect from "react-select/async";
import { notifySuccess, notifyWarn } from "../../../assets/elements/Toast";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import Button from "react-bootstrap/Button";
import { Form, Formik } from "formik";
import PageLoader from "../../../assets/loaders/PageLoading";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import moment from "moment";
import plusIcon from "../../../assets/images/icons/add-icon.svg";
import minusIcon from "../../../assets/images/icons/minus-icon.svg";
import useConfig from "../../../hooks/useConfig";
import useAuth from "../../../hooks/useAuth";

const ContactDetails = () => {
  const route = allRoutes;
  const [owner, setOwner] = useState(["Collab"]);
  const [selectedDate1, setSelectedDate1] = useState(new Date());
  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [selectedDate2, setSelectedDate2] = useState(new Date());
  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
  };
  const [selectedDate4, setSelectedDate4] = useState(new Date());
  const [pageLoading, setPageLoading] = useState(0);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [selectedFamilyMembers, setSelectedFamilyMembers] = useState({});
  const [totalFamilyMembers, setTotalFamilyMembers] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [addEmployeeModal, setAddEmployeeModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [digitalSignatureModal, setDigitalSignatureModal] = useState(false);
  const [documentDesignations, setDocumentDesignations] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [reportingMagers, setReportingMagers] = useState([]);
  const [accessRoles, setAccessRoles] = useState([]);
  const [documentDepartments, setDocumentDepartments] = useState([]);
  const [remaindersList, setRemaindersList] = useState([]);
  const [attachmentsByUser, setAttachmentsByUser] = useState([]);
  const [errorsOnSubmit, setErrorsOnSubmit] = useState([]);
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const [selectedDocType, setelectedDocType] = useState("");
  const [familyOptions, setFamilyOptions] = useState([]);
  const [selectedAttachment, setSelectedAttachment] = useState();
  const [selectedAttachmentDelete, setSelectedAttachmentDelete] = useState();
  const [documentModel, setDocumentModel] = useState(false);
  const [attachmentDeleteModel, setAttachmentDeleteModel] = useState(false);
  const [dateOfBirthChange, setDateOfBirthChange] = useState(false);
  const [empDetailsById, setEmpDetailsById] = useState({});
  const location = useLocation();
  const userId = new URLSearchParams(location.search).get("user_id");
  const edit = new URLSearchParams(location.search).get("edit");
  const [relationOptions, setRelationOptions] = useState([]);
  const [documentsById, setDocumentsById] = useState({});

  const attachmentSchema = Yup.object().shape({
    // userId: Yup.object().required("User is Required"),
    // familyId: Yup.object().required("Family is Required"),
    documentTypeId: Yup.object().required("Remainder Type is Required"),
    documentNumber: Yup.string()
      .transform((value) => value.trim())
      .required("Relation Number is Required"),
    issueDate: Yup.string().required("Issue Date is Required"),
    expiryDate: Yup.string().required("Expiry Date is Required"),
    renewDate: Yup.string().required("Renew Date is Required"),
    document: Yup.mixed().test("fileType", "Relation is required", (value) => {
      return value && value.length > 0;
    }),
  });

  const familyModelSchema = Yup.object().shape({
    name: Yup.string()
      .transform((value) => value.trim())
      .required("Name is Required"),
    relation: Yup.object().required("Relation is Required"),
    dateOfBirth: Yup.string().required("DateOfBirth is Required"),
    contactNo: Yup.string()
      .transform((value) => value.trim())
      .required("ContactNo is Required"),
  });

  const handleDateChange4 = (date) => {
    setSelectedDate4(date);
  };

  const {
    activityToggleTwo,
    setActivityTogglePopup,
    setActivityTogglePopupTwo,
    activityToggle,
  } = useTheme();

  const {
    getEmployeeMasters,
    getEmpProfile,
    updateEmployeeMaster,
    getDesignationMasters,
    getDepartmentMasters,
    getRoleMasters,
    deleteEmployeeMaster,
    addFamilyMember,
    getFamilyMember,
    getFamilyMemberById,
    updateFamilyMember,
    deleteFamilyMember,
    fileUpload,
    getRemainderRecords,
    getDocumentMasters,
    addDocumentMaster,
    getDocumentType,
    updateDocuments,
    updateDocumentStatus,
    getRelationType,
    getDocumentsByRelationType,
  } = MainServices();

  const { deBounce } = TimingFunctions();
  const { user } = useAuth();
  const { fileBasicPath } = useConfig();
  const familymodalRef = useRef();

  const dealsopen = [
    { value: "choose", label: "Choose" },
    { value: "collins", label: "Collins" },
    { value: "konopelski", label: "Konopelski" },
    { value: "adams", label: "Adams" },
    { value: "schumm", label: "Schumm" },
    { value: "wisozk", label: "Wisozk" },
  ];
  const activities = [
    { value: "choose", label: "Choose" },
    { value: "phoneCalls", label: "Phone Calls" },
    { value: "socialMedia", label: "Social Media" },
    { value: "referralSites", label: "Referral Sites" },
    { value: "webAnalytics", label: "Web Analytics" },
    { value: "previousPurchases", label: "Previous Purchases" },
  ];
  const industries = [
    { value: "choose", label: "Choose" },
    { value: "Retail Industry", label: "Retail Industry" },
    { value: "Banking", label: "Banking" },
    { value: "Hotels", label: "Hotels" },
    { value: "Financial Services", label: "Financial Services" },
    { value: "Insurance", label: "Insurance" },
  ];
  const countries = [
    { value: "Choose", label: "Choose" },
    { value: "India", label: "India" },
    { value: "USA", label: "USA" },
    { value: "France", label: "France" },
    { value: "UAE", label: "UAE" },
  ];

  const typeOptions = [
    { value: "text", label: "Text" },
    { value: "date", label: "Date" },
    { value: "boolean", label: "Boolean" },
  ];

  useEffect(() => {
    getEmpDetialsById();
    getDesignations([
      { key: "status", eq: "approved" },
      { key: "recordStatus", eq: "active" },
    ]);
    getDepartments([
      { key: "status", eq: "approved" },
      { key: "recordStatus", eq: "active" },
    ]);
    getEmployeeLists([
      { key: "status", eq: "approved" },
      { key: "recordStatus", eq: "active" },
    ]);
  }, []);

  useEffect(() => {
    getRemaindersList();
    getAttachmentstList();
    getDocumentTypeOptions();
    getFamilyOptions();
    getRelationOptions();
    getDocumentsById();
  }, [empDetailsById]);

  //apis
  const updateEmployee = async (values) => {
    // const roles=values.roleId.map(_=>_.publicId)
    const securityGroups =
      values.securityGroupId &&
      values.securityGroupId.length > 0 &&
      values.securityGroupId.map((_) => _.publicId);
    let workflowInfo = values?.workflows || [];
    let workflowFlag = false;
    workflowInfo =
      workflowInfo.length > 0
        ? workflowInfo.map((each) => ({
            ...each,
            users: each?.users?.map((user) => {
              workflowFlag = true;
              return {
                value: user?.publicId,
              };
            }),
            ...(each?.no_of_approvals
              ? { no_of_approvals: each?.no_of_approvals }
              : {
                  no_of_approvals: each?.users && each?.users.length,
                }),
          }))
        : [];

    setButtonLoading(true);

    let profilePhotoURL = values?.empProfilePhoto;

    if (typeof values?.empProfilePhoto !== "string") {
      let files = values.empProfilePhoto || "";
      let profilePhotoUploadResponse = await fileUpload(files, user.token);

      if (!profilePhotoUploadResponse) {
        notifyWarn("Error Occured While Updating Profile Photo");
        return;
      }

      profilePhotoURL = profilePhotoUploadResponse?.data?.fileName;
    }

    const details = {
      empName: values?.firstName + "-" + values?.lastName,
      empEmail: values?.email,
      empContact: values?.contactNumber,
      empCode: values?.employeeCode,
      userName: values?.email,
      deptId: values?.discipline?.publicId,
      desgId: values?.designation?.publicId,
      roleId: values?.role?.publicId,
      reportingTo: values?.reportingManager?.publicId,
      groupId: securityGroups || [],
      empProfilePhoto: profilePhotoURL,
      joiningDate: values?.joiningDate,
      ...(values?.password && { password: values?.password }),
      status: "active",
    };
    if (values.userPassword) {
      details.userPassword = values.userPassword;
    } else {
      details.userPassword = values.password;
      details.password = "";
    }
    if (workflowFlag) {
      details.workflowInfo = workflowInfo;
    }

    await axios
      .all([
        updateEmployeeMaster(
          details,
          empDetailsById.publicId,
          empDetailsById.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            // getEmployeeByStatus({
            //   page: pagination.page,
            //   limit: pagination.limit,
            //   filters,
            // });
            onAddEmployee(false);
            setDigitalSignatureModal(false);
            setActivityTogglePopup(!activityToggle);
            notifySuccess("Employee Master Edited Successfully");
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in adding Employee Master");
      });
  };

  const getEmpDetialsById = async () => {
    setPageLoading(true);
    const details = {};
    await axios
      .all([getEmpProfile(details, userId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let userDetail = resData.data ? resData.data : {};
            setEmpDetailsById(userDetail);
            userDetail?.familyMembers && userDetail?.familyMembers.length
              ? setFamilyMembers(userDetail?.familyMembers)
              : setFamilyMembers([]);
            setPageLoading(false);
          } else {
            if (message) notifyWarn(message);
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting User Details");
      });
  };

  const getDocumentsById = async () => {
    setPageLoading(true);
    const details = {};
    await axios
      .all([getDocumentsByRelationType(details, userId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let documentsByRelation = resData?.data?.data
              ? resData?.data?.data
              : {};
            setDocumentsById(documentsByRelation);

            setPageLoading(false);
          } else {
            if (message) notifyWarn(message);
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting User Details");
      });
  };

  const getDesignations = async (filters, search) => {
    const details = {
      filters,
      // status: "ACTIVE",
    };
    let results = await axios
      .all([getDesignationMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let designations = resData?.data || [];
            designations =
              designations && designations.length
                ? designations.map((each) => {
                    return {
                      ...each,
                      label: each?.desgName || "",
                      value: each?.publicId || "",
                    };
                  })
                : [];

            designations.unshift({ label: "Select Designation", value: "" });
            !search && setDocumentDesignations(designations);
            return designations;
          } else {
            if (message) notifyWarn(message);
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in getting Designations");
      });
    return results;
  };

  const getEmployeeLists = async (filters, search) => {
    const details = {
      filters,
    };
    let result = await axios
      .all([getEmployeeMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let reportingManager = resData.data ? resData.data : [];
            reportingManager = reportingManager.map((each) => {
              return {
                ...each,
                label: each?.empName,
                value: each?.publicId,
              };
            });
            reportingManager.unshift({ label: "Select Employee", value: "" });
            !search && setReportingMagers(reportingManager);
            return reportingManager;
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Employee Lists");
      });
    return result;
  };

  const getDepartments = async (filters, search) => {
    const details = {
      filters,
      // status: "active",
    };
    let results = await getDepartmentMasters(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            let departments = resData?.data;
            departments = departments.map((each) => {
              return {
                ...each,
                label: each?.deptName,
                value: each?.publicId,
              };
            });
            departments.unshift({ label: "Select Department", value: "" });
            !search && setDocumentDepartments(departments);
            return departments;
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in getting Departments");
      });
    return results;
  };

  const getRolePermissionMastersByStatus = async (filters, search) => {
    setPageLoading(true);
    const details = {
      // pageSize: limit || 20,
      // pageNumber: page || 1,
      filters,
    };
    let result = await axios
      .all([getRoleMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let role = resData.data ? resData.data : [];
            role = role.map((_, index) => {
              return {
                ..._,
                label: _?.role,
                value: _?.publicId,
              };
            });
            role.unshift({ label: "Select Role", value: "" });
            !search && setAccessRoles(role);
            setPageLoading(false);
            return role;
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Roles");
      });
    return result;
  };

  const deleteEmployee = async (values) => {
    setButtonLoading(true);
    const details = {
      status: "inactive",
      recordStatus: "inactive",
    };
    await axios
      .all([
        deleteEmployeeMaster(
          details,
          selectedEmployee.publicId,
          selectedEmployee.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            // getEmployeeByStatus({
            //   page: pagination.page,
            //   limit: pagination.limit,
            //   filters,
            // });
            // onDeleteEmployee(false);
            // setDigitalSignatureModal(false);
            setSelectedEmployee("");
            notifySuccess("Employee Master Deleted Successfully");
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in deleting Employee Master");
      });
  };

  //Family Member api
  const handleModalCloseOnSuccess = () => {
    if (familymodalRef.current) {
      familymodalRef.current.click();
    }
  };

  const addFamilyMembers = async (values) => {
    setButtonLoading(true);
    const details = {
      name: values?.name,
      relation: values?.relation.publicId,
      ...(dateOfBirthChange && { dateOfBirth: values?.dateOfBirth }),
      // dateOfBirth: moment(values?.dateOfBirth).format("YYYY-MM-DD"),
      contactNo: values?.contactNo,
      customFields: JSON.stringify(values.customFields),
      ...(empDetailsById &&
        Object.keys(empDetailsById) && { userId: empDetailsById?.publicId }),
      password: "Admin@123",
    };

    await axios
      .all([addFamilyMember(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            handleModalCloseOnSuccess();
            notifySuccess("Family Member Added Successfully");
            getEmpDetialsById();
            setButtonLoading(false);
          } else {
            notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in adding familyMember");
      });
  };

  const updateFamilyMembers = async (values) => {
    setButtonLoading(true);
    const details = {
      name: values?.name,
      relation: values?.relation.publicId,
      dateOfBirth: moment(values?.dateOfBirth).format("YYYY-MM-DD"),
      contactNo: values?.contactNo,
      customFields: JSON.stringify(values.customFields),
      password: "Admin@123",
    };

    await axios
      .all([
        updateFamilyMember(
          details,
          selectedFamilyMembers?.publicId,
          selectedFamilyMembers?.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            handleModalCloseOnSuccess();
            notifySuccess("Family Member Added Successfully");
            getEmpDetialsById();
            setButtonLoading(false);
          } else {
            notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in adding familyMember");
      });
  };

  const deleteFamilyMembers = async () => {
    setButtonLoading(true);
    const details = {
      recordStatus: "inactive",
    };
    await axios
      .all([
        deleteFamilyMember(
          details,
          selectedFamilyMembers?.publicId,
          selectedFamilyMembers?.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            // getFamilyMembers([
            //   { key: "status", eq: "approved" },
            //   { key: "recordStatus", eq: "active" },
            // ]);
            setSelectedFamilyMembers({});
            notifySuccess("Family Member Deleted Successfully");
            setButtonLoading(false);
          } else {
            notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in delete Remainder Type");
      });
  };

  const getRemaindersList = async () => {
    setPageLoading(true);
    const details = {
      filters: [{ key: "userId", eq: userId }],
    };
    await axios
      .all([getRemainderRecords(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let record = resData.data ? resData.data.data : [];
            setRemaindersList(record);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Departments");
      });
  };

  const getAttachmentstList = async () => {
    setPageLoading(true);
    const details = {
      // pageSize: pagination.limit || 10,
      // pageNumber: pagination.page || 1,
      ...(empDetailsById?.publicId && {
        filters: [{ key: "userId", eq: empDetailsById?.publicId }],
      }),
      // sorting : [{ key: "id", direction: "DESC" }],
    };

    await axios
      .all([getDocumentMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let docsOfUser = resData.data.data ? resData?.data.data : [];
            setAttachmentsByUser(docsOfUser);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Relation Type");
      });
  };

  const addAttachment = async (values) => {
    setButtonLoading(true);
    let toolFileURL = "";
    if (values?.document && Array.isArray(values.document)) {
      for (let i = 0; i < values.document.length; i++) {
        if (typeof values.document[i].file !== "string") {
          let files = values.document[i].file || [];
          try {
            let toolUploadResponse = await fileUpload(files, user.token);
            toolFileURL = toolUploadResponse?.data?.fileName;
            values.document[i].file = toolFileURL;

            if (!toolFileURL) {
              setErrorsOnSubmit((oldValues) => [
                ...oldValues,
                ...(toolUploadResponse?.details?.map(
                  (item) => `${item.name} ${item.message}`
                ) || []),
              ]);

              if (toolUploadResponse?.message === "Network Error") {
                setErrorsOnSubmit((oldValues) => [
                  ...oldValues,
                  "File size too large",
                ]);
              }

              return;
            }
          } catch (error) {
            setErrorsOnSubmit((oldValues) => [
              ...oldValues,
              "An error occurred during file upload.",
            ]);

            return;
          }
        }
      }
    } else {
      setErrorsOnSubmit((oldValues) => [
        ...oldValues,
        "Instrument images data is invalid.",
      ]);
    }

    const details = {
      userId: empDetailsById ? empDetailsById?.publicId : "",
      familyId: values?.familyId?.publicId,
      documentTypeId: values?.documentTypeId?.publicId,
      documentNumber: values?.documentNumber,
      issueDate: values?.issueDate,
      expiryDate: values?.expiryDate,
      renewDate: values?.renewDate,
      document: values?.document,
      customInfo: JSON.stringify(values.customInfo),
      status: "active",
    };
    await axios
      .all([addDocumentMaster(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getDocumentsById();
            setDocumentModel(false);
            notifySuccess("Attachment Added Successfully");
            setButtonLoading(false);
          } else {
            notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in adding Remainder Type");
      });
  };

  const updateAttachment = async (values) => {
    setButtonLoading(true);
    let toolFileURL = "";
    if (
      values?.document &&
      Array.isArray(values.document) &&
      values.document.length
    ) {
      for (let i = 0; i < values.document.length; i++) {
        if (typeof values.document[i].file !== "string") {
          let files = values.document[i].file || [];
          try {
            let toolUploadResponse = await fileUpload(files, user.token);
            toolFileURL = toolUploadResponse?.data?.fileName;
            values.document[i].file = toolFileURL;

            if (!toolFileURL) {
              setErrorsOnSubmit((oldValues) => [
                ...oldValues,
                ...(toolUploadResponse?.details?.map(
                  (item) => `${item.name} ${item.message}`
                ) || []),
              ]);

              if (toolUploadResponse?.message === "Network Error") {
                setErrorsOnSubmit((oldValues) => [
                  ...oldValues,
                  "File size too large",
                ]);
              }

              return;
            }
          } catch (error) {
            setErrorsOnSubmit((oldValues) => [
              ...oldValues,
              "An error occurred during file upload.",
            ]);

            return;
          }
        }
      }
    } else {
      setErrorsOnSubmit((oldValues) => [
        ...oldValues,
        "Instrument images data is invalid.",
      ]);
    }
    const details = {
      userId: empDetailsById ? empDetailsById?.publicId : "",
      familyId: values?.familyId?.publicId,
      documentTypeId: values?.documentTypeId?.publicId,
      documentNumber: values?.documentNumber,
      issueDate: values?.issueDate,
      expiryDate: values?.expiryDate,
      renewDate: values?.renewDate,
      document: values?.document,
      customInfo: JSON.stringify(values.customInfo),
    };

    await axios
      .all([
        updateDocuments(
          details,
          selectedAttachment?.publicId,
          selectedAttachment?.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getDocumentsById();
            setDocumentModel(false);
            notifySuccess("Attachment Updated Successfully");
            setButtonLoading(false);
          } else {
            notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in update Relation");
      });
  };

  const deleteAttachment = async (values) => {
    setButtonLoading(true);
    const details = {
      recordStatus: "inactive",
    };

    await axios
      .all([
        updateDocumentStatus(
          details,
          selectedAttachmentDelete?.publicId,
          selectedAttachmentDelete?.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getDocumentsById();
            notifySuccess("Attachment Deleted Successfully");
            setButtonLoading(false);
          } else {
            notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in delete Attachment");
      });
  };

  const getFamilyOptions = async (publicId, filters, search) => {
    const details = {
      ...(empDetailsById?.publicId && {
        filters: [
          { key: "userId", eq: empDetailsById?.publicId },
          { key: "status", eq: "approved" },
          { key: "recordStatus", eq: "active" },
          ...(filters ? filters : []),
        ],
      }),
    };
    let results = await axios
      .all([getFamilyMember(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let familyList = resData?.data || [];
            familyList =
              familyList && familyList.length
                ? familyList.map((each) => {
                    return {
                      ...each,
                      label: each?.name || "",
                      value: each?.publicId || "",
                    };
                  })
                : [];

            // familyList.unshift({ label: "Select Family Id", value: "" });
            !search && setFamilyOptions(familyList);
            return familyList;
          } else {
            if (message) notifyWarn(message);
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res.message) console.log("An error occurred in getting Family");
      });
    return results;
  };

  const getRelationOptions = async (filters, search) => {
    const details = {
      filters: [
        { key: "status", eq: "approved" },
        { key: "recordStatus", eq: "active" },
        ...(filters ? filters : []),
      ],
    };
    let results = await axios
      .all([getRelationType(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let relation = resData.data.data ? resData?.data.data : [];
            relation =
              relation && relation.length
                ? relation.map((each) => {
                    return {
                      ...each,
                      label: each?.name || "",
                      value: each?.publicId || "",
                    };
                  })
                : [];
            relation.unshift({ label: "Select Relation Type", value: "" });
            !search && setRelationOptions(relation);
            return relation;
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Relation Type");
      });
    return results;
  };

  const getDocumentTypeOptions = async (filters, search) => {
    const details = {
      filters: [
        { key: "status", eq: "approved" },
        { key: "recordStatus", eq: "active" },
        ...(filters ? filters : []),
      ],
    };
    if (docTypeOptions?.publicId) {
      details.filters.push({ key: "name", eq: docTypeOptions.publicId });
    }
    let results = await axios
      .all([getDocumentType(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let docTypeList = resData?.data?.data || [];
            docTypeList =
              docTypeList && docTypeList.length
                ? docTypeList.map((each) => {
                    return {
                      ...each,
                      label: each?.name || "",
                      value: each?.publicId || "",
                    };
                  })
                : [];

            docTypeList.unshift({ label: "Select Documnet Type", value: "" });
            !search && setDocTypeOptions(docTypeList);
            return docTypeList;
          } else {
            if (message) notifyWarn(message);
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in getting Remainder Type");
      });
    return results;
  };

  //functions
  const onAddEmployee = (selected) => {
    if (selected) {
      setSelectedEmployee(selected);
    } else {
      setSelectedEmployee("");
    }
    setAddEmployeeModal(!addEmployeeModal);
  };

  const onEditFamilyMemberModal = (selected) => {
    if (selected) {
      setSelectedFamilyMembers(selected);
    }
    setActivityTogglePopup(!activityToggle);
  };

  const onDeleteFamilyMemberModal = (selected) => {
    if (selected) {
      setSelectedFamilyMembers(selected);
    }
    setActivityTogglePopup(!activityToggle);
  };

  const onSearchdDeptDebounce = (inputValue, callback) => {
    deBounce(() => onSearchdDept(inputValue, callback), 1000);
  };

  const onSearchdDept = async (inputValue, callback) => {
    if (!inputValue) {
      callback(documentDepartments);
      return;
    }

    let filter = [
      {
        key: "deptName",
        iLike: inputValue,
      },
      { key: "status", eq: "approved" },
      { key: "recordStatus", eq: "active" },
    ];
    let options = await getDepartments(filter, true);
    callback(options);
  };

  const onSearchdDesigDebounce = (inputValue, callback) => {
    deBounce(() => onSearchdDesig(inputValue, callback), 1000);
  };

  const onSearchdDesig = async (inputValue, callback) => {
    if (!inputValue) {
      callback(documentDesignations);
      return;
    }

    let filter = [
      {
        key: "desgName",
        iLike: inputValue,
      },
      { key: "status", eq: "approved" },
      { key: "recordStatus", eq: "active" },
    ];
    let options = await getDesignations(filter, true);
    callback(options);
  };

  const onSearchdRoleDebounce = (inputValue, callback) => {
    deBounce(() => onSearchdRole(inputValue, callback), 1000);
  };

  const onSearchdRole = async (inputValue, callback) => {
    if (!inputValue) {
      callback(accessRoles);
      return;
    }

    let filter = [
      {
        key: "empName",
        iLike: inputValue,
      },
      { key: "status", eq: "approved" },
      { key: "recordStatus", eq: "active" },
    ];
    let options = await getEmployeeLists(filter, true);
    callback(options);
  };

  const onSearchdReprotManagerDebounce = (inputValue, callback) => {
    deBounce(() => onSearchdReprotManager(inputValue, callback), 1000);
  };

  const onSearchdReprotManager = async (inputValue, callback) => {
    if (!inputValue) {
      callback(accessRoles);
      return;
    }

    let filter = [
      {
        key: "role",
        iLike: inputValue,
      },
      { key: "status", eq: "approved" },
      { key: "recordStatus", eq: "active" },
    ];
    let options = await getRolePermissionMastersByStatus(filter, true);
    callback(options);
  };

  const onSearchRelationDebounce = (inputValue, callback) => {
    deBounce(() => onSearchRelation(inputValue, callback), 1000);
  };

  const onSearchRelation = async (inputValue, callback) => {
    if (!inputValue) {
      callback(relationOptions);
      return;
    }

    let filter = [
      {
        key: "name",
        iLike: inputValue,
      },
    ];
    let options = await getRelationOptions(filter, true);
    callback(options);
  };

  const onSearchFamilyDebounce = (inputValue, callback) => {
    deBounce(() => onSearchFamily(inputValue, callback), 1000);
  };

  const onSearchFamily = async (inputValue, callback) => {
    if (!inputValue) {
      callback(familyOptions);
      return;
    }

    let filter = [
      {
        key: "name",
        iLike: inputValue,
      },
    ];
    let options = await getFamilyOptions(filter, true);
    callback(options);
  };

  const onSearchDocTypeDebounce = (inputValue, callback) => {
    deBounce(() => onSearchDocType(inputValue, callback), 1000);
  };

  const onSearchDocType = async (inputValue, callback) => {
    if (!inputValue) {
      callback(docTypeOptions);
      return;
    }

    let filter = [
      {
        key: "name",
        iLike: inputValue,
      },
    ];
    let options = await getDocumentTypeOptions(filter, true);
    callback(options);
  };

  const onAddDocumentType = (selected) => {
    if (selected) {
      setSelectedAttachment(selected);
    } else {
      setSelectedAttachment("");
    }
    setDocumentModel(!documentModel);
  };

  const onDeleteAttachment = (selected) => {
    if (selected) {
      setSelectedAttachmentDelete(selected);
    } else {
      setSelectedAttachmentDelete("");
    }

    if (selectedAttachmentDelete) {
      setAttachmentDeleteModel(!attachmentDeleteModel);
    }
  };

  const onDeleteEmployee = (selected) => {
    if (selected) {
      setSelectedEmployee(selected);
    } else {
      setSelectedEmployee("");
    }
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-10">
                    <h4 className="page-title">Employee Details</h4>
                  </div>
                  <div className="col-sm-2 text-sm-start">
                    <div className="head-icons">
                      <CollapseHeader />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
            </div>
          </div>
          {pageLoading ? (
            <PageLoader />
          ) : empDetailsById ? (
            <div className="row">
              <div className="col-md-12">
                {/* Contact User */}
                <div className="contact-head">
                  <div className="row align-items-center">
                    <div className="col-sm-6">
                      <ul className="contact-breadcrumb">
                        <li>
                          <Link to={route.employee}>
                            <i className="ti ti-arrow-narrow-left" />
                            Employee
                          </Link>
                        </li>
                        <li>
                          {empDetailsById?.empName
                            ? empDetailsById?.empName.split("-").join(" ")
                            : ""}
                        </li>
                      </ul>
                    </div>
                    {/* <div className="col-sm-6 text-sm-end">
                    <div className="contact-pagination">
                      <p>1 of 40</p>
                      <ul>
                        <li>
                          <Link to={route.contactsDetails}>
                            <i className="ti ti-chevron-left" />
                          </Link>
                        </li>
                        <li>
                          <Link to={route.contactsDetails}>
                            <i className="ti ti-chevron-right" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  </div>
                </div>
                <div className="contact-wrap">
                  <div className="contact-profile">
                    <div className="avatar avatar-xxl">
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-14.jpg"
                        alt="img"
                      />
                      <span className="status online" />
                    </div>
                    <div className="name-user">
                      <h5>
                        {empDetailsById?.empName
                          ? empDetailsById?.empName.split("-").join(" ")
                          : ""}
                      </h5>
                      <p className="text-capitalize">
                        {empDetailsById?.userRole?.role?.role || ""}
                      </p>
                      {/* <div className="badge-rate">
                      <span className="badge badge-light">
                        <i className="ti ti-lock" />
                        Private
                      </span>
                      <p>
                        <i className="fa-solid fa-star" /> 5.0
                      </p>
                    </div> */}
                    </div>
                  </div>
                  <div className="contacts-action">
                    {/* <Link to="#" className="btn-icon rating">
                    <i className="fa-solid fa-star" />
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-danger add-popup"
                    onClick={() => setActivityTogglePopup(!activityToggle)}
                  >
                    <i className="ti ti-circle-plus" />
                    Add Deal
                  </Link> */}
                    <Link
                      to="#"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#add_compose"
                    >
                      <i className="ti ti-mail" />
                      Send Email
                    </Link>
                    {/* <Link to={route.chat} className="btn-icon">
                    <i className="ti ti-brand-hipchat" />
                  </Link> */}
                    <div className="act-dropdown">
                      <Link
                        to="#"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="ti ti-dots-vertical" />
                      </Link>
                      <div className="dropdown-menu dropdown-menu-right">
                        <Link
                          className="dropdown-item"
                          to={`${route.contactsDetails}?user_id=${userId}&edit=true`}
                        >
                          <i className="ti ti-edit text-blue" />
                          Edit
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_contact"
                          onClick={() => onDeleteEmployee(empDetailsById)}
                        >
                          <i className="ti ti-trash text-danger" />
                          Delete
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Contact User */}
              </div>
              {/* Contact Sidebar */}
              <div className="col-xl-3 theiaStickySidebar">
                <div className="stickybar">
                  <div className="contact-sidebar">
                    <h6>Basic Information</h6>
                    <ul className="basic-info">
                      <li>
                        <span>
                          <i className="ti ti-mail" />
                        </span>
                        <p>{empDetailsById?.empEmail || ""}</p>
                      </li>
                      <li>
                        <span>
                          <i className="ti ti-phone" />
                        </span>
                        <p>{empDetailsById?.empContact || ""}</p>
                      </li>
                      <li>
                        <span>
                          <i className="ti ti-calendar-exclamation" />
                        </span>
                        <p>
                          Joined on{" "}
                          {moment(empDetailsById?.joiningDate).format(
                            "DD-MM-YYYY"
                          ) || ""}
                        </p>
                      </li>
                    </ul>
                    <h6>Other Information</h6>
                    <ul className="other-info">
                      <li>
                        <span className="other-title">Department</span>
                        <span>
                          {empDetailsById?.deptartment?.deptName || ""}
                        </span>
                      </li>
                      <li>
                        <span className="other-title">Designation</span>
                        <span>
                          {empDetailsById?.designation?.desgName || ""}
                        </span>
                      </li>
                    </ul>
                    {/* <h6>Tags</h6>
                  <ul className="tag-info">
                    <li>
                      <Link
                        to="#"
                        className="badge badge-tag badge-success-light"
                      >
                        Collab
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="badge badge-tag badge-warning-light"
                      >
                        Rated
                      </Link>
                    </li>
                  </ul> */}
                    {/* <div className="con-sidebar-title">
                    <h6>Company</h6>
                    <Link to="#" className="com-add add-popups">
                      <i className="ti ti-circle-plus me-1" />
                      Add New
                    </Link>
                  </div> */}
                    {/* <ul className="company-info">
                    <li>
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/google-icon.svg"
                          alt=""
                        />
                      </span>
                      <div>
                        <h6>
                          Google. Inc{" "}
                          <i className="fa-solid fa-circle-check text-success" />
                        </h6>
                        <p>www.google.com</p>
                      </div>
                    </li>
                  </ul> */}
                    {/* <h6>Social Profile</h6>
                  <ul className="social-info">
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-youtube" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-facebook-f" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-whatsapp" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-pinterest" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-linkedin" />
                      </Link>
                    </li>
                  </ul> */}
                    <h6>Settings</h6>
                    <ul className="set-info">
                      <li>
                        <Link to="#">
                          <i className="ti ti-share-2" />
                          Share Contact
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="ti ti-star" />
                          Add to Favourite
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_contact"
                          onClick={() => onDeleteEmployee(empDetailsById)}
                        >
                          <i className="ti ti-trash-x" />
                          Delete Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* /Contact Sidebar */}
              {/* Contact Details */}
              <div className="col-xl-9">
                <div className="contact-tab-wrap">
                  <ul className="contact-nav nav">
                    <li>
                      <Link
                        to="#"
                        data-bs-toggle="tab"
                        data-bs-target="#activities"
                        className="active"
                      >
                        <i className="ti ti-alarm-minus" />
                        Events
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="#" data-bs-toggle="tab" data-bs-target="#notes">
                        <i className="ti ti-notes" />
                        Notes
                      </Link>
                    </li>
                    <li>
                      <Link to="#" data-bs-toggle="tab" data-bs-target="#calls">
                        <i className="ti ti-phone" />
                        Calls
                      </Link>
                    </li> */}
                    <li>
                      <Link to="#" data-bs-toggle="tab" data-bs-target="#files">
                        <i className="ti ti-file" />
                        Attachments
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="#" data-bs-toggle="tab" data-bs-target="#email">
                        <i className="ti ti-mail-check" />
                        Email
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="#"
                        data-bs-toggle="tab"
                        data-bs-target="#familyMembers"
                      >
                        <i class="fas fa-users" />
                        Family Members
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* Tab Content */}
                <div className="contact-tab-view">
                  <div className="tab-content pt-0">
                    {/* Activities */}
                    <div className="tab-pane active show" id="activities">
                      <div className="view-header">
                        <h4>Events</h4>
                        {/* <ul>
                          <li>
                            <div className="form-sort">
                              <i className="ti ti-sort-ascending-2" />

                              <Select
                                className="select"
                                options={ascendingandDecending}
                                placeholder="Ascending"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </li>
                        </ul> */}
                      </div>
                      <div className="contact-activity">
                        {/* <div className="badge-day">
                          <i className="ti ti-calendar-check" />
                          29 Aug 2023
                        </div> */}
                        <ul>
                          {remaindersList?.length > 0 &&
                            remaindersList.map((eachRemainder) => (
                              <li
                                key={eachRemainder?.id}
                                className="activity-wrap"
                              >
                                <span className="activity-icon bg-pending">
                                  <i className="ti ti-mail-code" />
                                </span>
                                <div className="activity-info mx-2 d-flex justify-content-between align-items-center w-100">
                                  <h6>
                                    {`Your ${eachRemainder?.document?.documentType?.name} : ${eachRemainder?.document?.documentNumber} is Expiring !`}
                                  </h6>
                                  {eachRemainder?.expiryDate && (
                                    <div className="badge-day m-0">
                                      <i className="ti ti-calendar-check" />
                                      {`On : ${moment(
                                        eachRemainder.expiryDate
                                      ).format("DD-MM-YYYY")}`}
                                    </div>
                                  )}
                                </div>
                              </li>
                            ))}
                          {/* <li className="activity-wrap">
                            <span className="activity-icon bg-secondary-success">
                              <i className="ti ti-phone" />
                            </span>
                            <div className="activity-info">
                              <h6>
                                Denwar responded to your appointment schedule
                                question by call at 09:30pm.
                              </h6>
                              <p>09:25 pm</p>
                            </div>
                          </li>
                          <li className="activity-wrap">
                            <span className="activity-icon bg-orange">
                              <i className="ti ti-notes" />
                            </span>
                            <div className="activity-info">
                              <h6>Notes added by Antony</h6>
                              <p>
                                Please accept my apologies for the inconvenience
                                caused. It would be much appreciated if it's
                                possible to reschedule to 6:00 PM, or any other
                                day that week.
                              </p>
                              <p>10.00 pm</p>
                            </div>
                          </li> */}
                        </ul>
                        {/* <div className="badge-day">
                          <i className="ti ti-calendar-check" />
                          28 Feb 2024
                        </div>
                        <ul>
                          <li className="activity-wrap">
                            <span className="activity-icon bg-info">
                              <i className="ti ti-user-pin" />
                            </span>
                            <div className="activity-info">
                              <h6>
                                Meeting With{" "}
                                <span className="avatar-xs">
                                  <ImageWithBasePath
                                    src="assets/img/profiles/avatar-19.jpg"
                                    alt="img"
                                  />
                                </span>{" "}
                                Abraham
                              </h6>
                              <p>Schedueled on 05:00 pm</p>
                            </div>
                          </li>
                          <li className="activity-wrap">
                            <span className="activity-icon bg-secondary-success">
                              <i className="ti ti-phone" />
                            </span>
                            <div className="activity-info">
                              <h6>
                                Drain responded to your appointment schedule
                                question.
                              </h6>
                              <p>09:25 pm</p>
                            </div>
                          </li>
                        </ul>
                        <div className="badge-day">
                          <i className="ti ti-calendar-check" />
                          Upcoming Activity
                        </div>
                        <ul>
                          <li className="activity-wrap">
                            <span className="activity-icon bg-info">
                              <i className="ti ti-user-pin" />
                            </span>
                            <div className="activity-info">
                              <h6>Product Meeting</h6>
                              <p>
                                A product team meeting is a gathering of the
                                cross-functional product team — ideally
                                including team members from product,
                                engineering, marketing, and customer support.
                              </p>
                              <p>25 Jul 2023, 05:00 pm</p>
                              <div className="upcoming-info">
                                <div className="row">
                                  <div className="col-sm-4">
                                    <p>Reminder</p>
                                    <div className="dropdown">
                                      <Link
                                        to="#"
                                        className="dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="ti ti-clock-edit me-1" />
                                        Reminder
                                        <i className="ti ti-chevron-down ms-1" />
                                      </Link>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <Link className="dropdown-item" to="#">
                                          Remainder
                                        </Link>
                                        <Link className="dropdown-item" to="#">
                                          1 hr
                                        </Link>
                                        <Link className="dropdown-item" to="#">
                                          10 hr
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    <p>Task Priority</p>
                                    <div className="dropdown">
                                      <Link
                                        to="#"
                                        className="dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="ti ti-square-rounded-filled me-1 text-danger circle" />
                                        High
                                        <i className="ti ti-chevron-down ms-1" />
                                      </Link>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <Link className="dropdown-item" to="#">
                                          <i className="ti ti-square-rounded-filled me-1 text-danger circle" />
                                          High
                                        </Link>
                                        <Link className="dropdown-item" to="#">
                                          <i className="ti ti-square-rounded-filled me-1 text-success circle" />
                                          Low
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    <p>Assigned to</p>
                                    <div className="dropdown">
                                      <Link
                                        to="#"
                                        className="dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <ImageWithBasePath
                                          src="assets/img/profiles/avatar-19.jpg"
                                          alt="img"
                                          className="avatar-xs"
                                        />
                                        John
                                        <i className="ti ti-chevron-down ms-1" />
                                      </Link>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <Link className="dropdown-item" to="#">
                                          <ImageWithBasePath
                                            src="assets/img/profiles/avatar-19.jpg"
                                            alt="img"
                                            className="avatar-xs"
                                          />
                                          John
                                        </Link>
                                        <Link className="dropdown-item" to="#">
                                          <ImageWithBasePath
                                            src="assets/img/profiles/avatar-19.jpg"
                                            alt="img"
                                            className="avatar-xs"
                                          />
                                          Peter
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                    {/* /Activities */}
                    {/* Notes */}
                    {/* <div className="tab-pane fade" id="notes">
                      <div className="view-header">
                        <h4>Notes</h4>
                        <ul>
                          <li>
                            <div className="form-sort">
                              <i className="ti ti-sort-ascending-2" />
                              <Select
                                className="select"
                                options={ascendingandDecending}
                                placeholder="Ascending"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </li>
                          <li>
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#add_notes"
                              className="com-add"
                            >
                              <i className="ti ti-circle-plus me-1" />
                              Add New
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="notes-activity">
                        <div className="calls-box">
                          <div className="caller-info">
                            <div className="calls-user">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-19.jpg"
                                alt="img"
                              />
                              <div>
                                <h6>Darlee Robertson</h6>
                                <p>15 Sep 2023, 12:10 pm</p>
                              </div>
                            </div>
                            <div className="calls-action">
                              <div className="dropdown action-drop">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="ti ti-dots-vertical" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link className="dropdown-item" to="#">
                                    <i className="ti ti-edit text-blue" />
                                    Edit
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    <i className="ti ti-trash text-danger" />
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h5>Notes added by Antony</h5>
                          <p>
                            A project review evaluates the success of an
                            initiative and identifies areas for improvement. It
                            can also evaluate a current project to determine
                            whether it's on the right track. Or, it can
                            determine the success of a completed project.{" "}
                          </p>
                          <ul>
                            <li>
                              <div className="note-download">
                                <div className="note-info">
                                  <span className="note-icon bg-secondary-success">
                                    <i className="ti ti-file-spreadsheet" />
                                  </span>
                                  <div>
                                    <h6>Project Specs.xls</h6>
                                    <p>365 KB</p>
                                  </div>
                                </div>
                                <Link to="#">
                                  <i className="ti ti-arrow-down" />
                                </Link>
                              </div>
                            </li>
                            <li>
                              <div className="note-download">
                                <div className="note-info">
                                  <span className="note-icon">
                                    <ImageWithBasePath
                                      src="assets/img/media/media-35.jpg"
                                      alt="img"
                                    />
                                  </span>
                                  <div>
                                    <h6>090224.jpg</h6>
                                    <p>365 KB</p>
                                  </div>
                                </div>
                                <Link to="#">
                                  <i className="ti ti-arrow-down" />
                                </Link>
                              </div>
                            </li>
                          </ul>
                          <div className="notes-editor">
                            <div className="note-edit-wrap">
                              <div className="summernote">
                                Write a new comment, send your team notification
                                by typing @ followed by their name
                              </div>
                              <div className="text-end note-btns">
                                <Link
                                  to="#"
                                  className="btn btn-light add-cancel"
                                >
                                  Cancel
                                </Link>
                                <Link to="#" className="btn btn-primary">
                                  Save
                                </Link>
                              </div>
                            </div>
                            <div className="text-end">
                              <Link to="#" className="add-comment">
                                <i className="ti ti-square-plus me-1" />
                                Add Comment
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="calls-box">
                          <div className="caller-info">
                            <div className="calls-user">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-20.jpg"
                                alt="img"
                              />
                              <div>
                                <h6>Sharon Roy</h6>
                                <p>18 Sep 2023, 09:52 am</p>
                              </div>
                            </div>
                            <div className="calls-action">
                              <div className="dropdown action-drop">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="ti ti-dots-vertical" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link className="dropdown-item" to="#">
                                    <i className="ti ti-edit text-blue" />
                                    Edit
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    <i className="ti ti-trash text-danger" />
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h5>Notes added by Antony</h5>
                          <p>
                            A project plan typically contains a list of the
                            essential elements of a project, such as
                            stakeholders, scope, timelines, estimated cost and
                            communication methods. The project manager typically
                            lists the information based on the assignment.
                          </p>
                          <ul>
                            <li>
                              <div className="note-download">
                                <div className="note-info">
                                  <span className="note-icon bg-secondary-success">
                                    <i className="ti ti-file-text" />
                                  </span>
                                  <div>
                                    <h6>Andrewpass.txt</h6>
                                    <p>365 KB</p>
                                  </div>
                                </div>
                                <Link to="#">
                                  <i className="ti ti-arrow-down" />
                                </Link>
                              </div>
                            </li>
                          </ul>
                          <div className="reply-box">
                            <p>
                              The best way to get a project done faster is to
                              start sooner. A goal without a timeline is just a
                              dream.The goal you set must be challenging. At the
                              same time, it should be realistic and attainable,
                              not impossible to reach.
                            </p>
                            <p>
                              Commented by{" "}
                              <span className="text-purple">Aeron</span> on 15
                              Sep 2023, 11:15 pm
                            </p>
                            <Link to="#" className="btn">
                              <i className="ti ti-arrow-back-up-double" />
                              Reply
                            </Link>
                          </div>
                          <div className="notes-editor">
                            <div className="note-edit-wrap">
                              <div className="summernote">
                                Write a new comment, send your team notification
                                by typing @ followed by their name
                              </div>
                              <div className="text-end note-btns">
                                <Link
                                  to="#"
                                  className="btn btn-light add-cancel"
                                >
                                  Cancel
                                </Link>
                                <Link to="#" className="btn btn-primary">
                                  Save
                                </Link>
                              </div>
                            </div>
                            <div className="text-end">
                              <Link to="#" className="add-comment">
                                <i className="ti ti-square-plus me-1" />
                                Add Comment
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="calls-box">
                          <div className="caller-info">
                            <div className="calls-user">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-21.jpg"
                                alt="img"
                              />
                              <div>
                                <h6>Vaughan</h6>
                                <p>20 Sep 2023, 10:26 pm</p>
                              </div>
                            </div>
                            <div className="calls-action">
                              <div className="dropdown action-drop">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="ti ti-dots-vertical" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link className="dropdown-item" to="#">
                                    <i className="ti ti-edit text-blue" />
                                    Edit
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    <i className="ti ti-trash text-danger" />
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p>
                            Projects play a crucial role in the success of
                            organizations, and their importance cannot be
                            overstated. Whether it's launching a new product,
                            improving an existing
                          </p>
                          <div className="notes-editor">
                            <div className="note-edit-wrap">
                              <div className="summernote">
                                Write a new comment, send your team notification
                                by typing @ followed by their name
                              </div>
                              <div className="text-end note-btns">
                                <Link
                                  to="#"
                                  className="btn btn-light add-cancel"
                                >
                                  Cancel
                                </Link>
                                <Link to="#" className="btn btn-primary">
                                  Save
                                </Link>
                              </div>
                            </div>
                            <div className="text-end">
                              <Link to="#" className="add-comment">
                                <i className="ti ti-square-plus me-1" />
                                Add Comment
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* /Notes */}
                    {/* Calls */}
                    {/* <div className="tab-pane fade" id="calls">
                      <div className="view-header">
                        <h4>Calls</h4>
                        <ul>
                          <li>
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#create_call"
                              className="com-add"
                            >
                              <i className="ti ti-circle-plus me-1" />
                              Add New
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="calls-activity">
                        <div className="calls-box">
                          <div className="caller-info">
                            <div className="calls-user">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-19.jpg"
                                alt="img"
                              />
                              <p>
                                <span>Darlee Robertson</span> logged a call on
                                23 Jul 2023, 10:00 pm
                              </p>
                            </div>
                            <div className="calls-action">
                              <div className="dropdown call-drop">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  Busy
                                  <i className="ti ti-chevron-down ms-2" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link className="dropdown-item" to="#">
                                    Busy
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    No Answer
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    Unavailable
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    Wrong Number
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    Left Voice Message
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    Moving Forward
                                  </Link>
                                </div>
                              </div>
                              <div className="dropdown action-drop">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="ti ti-dots-vertical" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link className="dropdown-item" to="#">
                                    <i className="ti ti-edit text-blue" />
                                    Edit
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    <i className="ti ti-trash text-danger" />
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p>
                            A project review evaluates the success of an
                            initiative and identifies areas for improvement. It
                            can also evaluate a current project to determine
                            whether it's on the right track. Or, it can
                            determine the success of a completed project.{" "}
                          </p>
                        </div>
                        <div className="calls-box">
                          <div className="caller-info">
                            <div className="calls-user">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-20.jpg"
                                alt="img"
                              />
                              <p>
                                <span>Sharon Roy</span> logged a call on 28 Jul
                                2023, 09:00 pm
                              </p>
                            </div>
                            <div className="calls-action">
                              <div className="dropdown call-drop">
                                <Link
                                  to="#"
                                  className="dropdown-toggle bg-pending"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  No Answer
                                  <i className="ti ti-chevron-down ms-2" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link className="dropdown-item" to="#">
                                    Busy
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    No Answer
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    Unavailable
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    Wrong Number
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    Left Voice Message
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    Moving Forward
                                  </Link>
                                </div>
                              </div>
                              <div className="dropdown action-drop">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="ti ti-dots-vertical" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link className="dropdown-item" to="#">
                                    <i className="ti ti-edit text-blue" />
                                    Edit
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    <i className="ti ti-trash text-danger" />
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p>
                            A project plan typically contains a list of the
                            essential elements of a project, such as
                            stakeholders, scope, timelines, estimated cost and
                            communication methods. The project manager typically
                            lists the information based on the assignment.
                          </p>
                        </div>
                        <div className="calls-box">
                          <div className="caller-info">
                            <div className="calls-user">
                              <ImageWithBasePath
                                src="assets/img/profiles/avatar-21.jpg"
                                alt="img"
                              />
                              <p>
                                <span>Vaughan</span> logged a call on 30 Jul
                                2023, 08:00 pm
                              </p>
                            </div>
                            <div className="calls-action">
                              <div className="dropdown call-drop">
                                <Link
                                  to="#"
                                  className="dropdown-toggle bg-pending"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  No Answer
                                  <i className="ti ti-chevron-down ms-2" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link className="dropdown-item" to="#">
                                    Busy
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    No Answer
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    Unavailable
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    Wrong Number
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    Left Voice Message
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    Moving Forward
                                  </Link>
                                </div>
                              </div>
                              <div className="dropdown action-drop">
                                <Link
                                  to="#"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="ti ti-dots-vertical" />
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link className="dropdown-item" to="#">
                                    <i className="ti ti-edit text-blue" />
                                    Edit
                                  </Link>
                                  <Link className="dropdown-item" to="#">
                                    <i className="ti ti-trash text-danger" />
                                    Delete
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p>
                            Projects play a crucial role in the success of
                            organizations, and their importance cannot be
                            overstated. Whether it's launching a new product,
                            improving an existing
                          </p>
                        </div>
                      </div>
                    </div> */}
                    {/* /Calls */}
                    {/* Files */}
                    <div className="tab-pane fade" id="files">
                      <div className="view-header d-flex justify-content-between align-items-center">
                        <h4>Attachments</h4>
                        <div
                         
                          className="btn btn-primary"
                          // data-bs-toggle="modal"
                          // data-bs-target="#new_file"
                          onClick={onAddDocumentType}
                        >
                          <i className="ti ti-plus" />
                          Add Attachment
                        </div>
                      </div>
                      <div className="files-activity">
                        {/* <div className="files-wrap">
                          <div className="row align-items-center">
                            <div className="col-md-8">
                              <div className="file-info">
                                <h4>Manage Documents</h4>
                                <p>
                                  Send customizable quotes, proposals and
                                  contracts to close deals faster.
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 text-md-end">
                              <ul className="file-action">
                                <li>
                                  <Link
                                    to="#"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#new_file"
                                  >
                                    Create Document
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> */}

                        {documentsById &&
                          documentsById?.length > 0 &&
                          documentsById.map((eachAttachment, index) => (
                            <div key={index}>
                              <div className="d-flex gap-2 mt-3">
                               <p className="fw-bold text-capitalize">{eachAttachment?.personName}</p><p>{"("}{eachAttachment?.relationName}{")"}</p>
                              </div>
                              {eachAttachment?.documents &&
                                eachAttachment?.documents?.length > 0 &&
                                eachAttachment?.documents.map(
                                  (eachDoc, index) => (
                                    <div className="files-wrap" key={index}>
                                      <div className="row align-items-center">
                                        <div className="col-md-8">
                                          <div className="file-info">
                                            <h4 className="badge badge-tag bg-pending priority-badge fs-6">
                                              {eachDoc?.documentType?.name ||
                                                ""}
                                            </h4>
                                          </div>
                                          <div>
                                            <ul
                                              style={{
                                                paddingLeft: "1rem",
                                                fontSize: "small",
                                              }}
                                            >
                                              <li>
                                                <b>Attachment No :</b>{" "}
                                                <span className="badge badge-tag badge-danger-light">
                                                  {eachDoc?.documentNumber ||
                                                    "-"}
                                                </span>
                                              </li>
                                              <li>
                                                <b>Description :</b>{" "}
                                                {eachDoc?.description || "-"}
                                              </li>
                                              <li>
                                                <div className="d-flex gap-2">
                                                  <b>Documents :</b>{" "}
                                                  {eachDoc?.document &&
                                                  eachDoc.document.length > 0
                                                    ? eachDoc.document.map(
                                                        (doc, index) => (
                                                          <div key={index}>
                                                            <a
                                                              href={
                                                                fileBasicPath +
                                                                doc.file
                                                              }
                                                              rel="noopener noreferrer"
                                                            >
                                                              {doc?.name}
                                                            </a>
                                                          </div>
                                                        )
                                                      )
                                                    : "-"}
                                                </div>
                                            
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="col-md-4 text-md-end d-flex justify-content-end">
                                          <ul className="file-action">
                                            <li>
                                              <div className="dropdown action-drop">
                                                <Link
                                                  to="#"
                                                  className="dropdown-toggle"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <i className="ti ti-dots-vertical" />
                                                </Link>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                  <div
                                                    className="dropdown-item"
                                                  
                                                    onClick={() =>
                                                      onAddDocumentType(
                                                        eachDoc
                                                      )
                                                    }
                                                  >
                                                    <i className="ti ti-edit text-blue" />
                                                    Edit
                                                  </div>
                                                  <div
                                                    className="dropdown-item"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#delete_attachment"
                                                    onClick={() =>
                                                      onDeleteAttachment(
                                                        eachDoc
                                                      )
                                                    }
                                                  >
                                                    <i className="ti ti-trash text-danger" />
                                                    Delete
                                                  </div>
                                                  <Link
                                                    className="dropdown-item"
                                                    to="#"
                                                  >
                                                    <i className="ti ti-download text-info" />
                                                    Download
                                                  </Link>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                          ))}
                        {/* <div className="files-wrap">
                          <div className="row align-items-center">
                            <div className="col-md-8">
                              <div className="file-info">
                                <h4>Collier-Turner Proposal</h4>
                                <p>
                                  Send customizable quotes, proposals and
                                  contracts to close deals faster.
                                </p>
                                <div className="file-user">
                                  <ImageWithBasePath
                                    src="assets/img/profiles/avatar-01.jpg"
                                    alt="img"
                                  />
                                  <div>
                                    <p>
                                      <span>Owner</span> Jessica
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 text-md-end">
                              <ul className="file-action">
                                <li>
                                  <span className="badge badge-tag badge-purple-light">
                                    Quote
                                  </span>
                                </li>
                                <li>
                                  <span className="badge bg-success priority-badge">
                                    Sent
                                  </span>
                                </li>
                                <li>
                                  <div className="dropdown action-drop">
                                    <Link
                                      to="#"
                                      className="dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="ti ti-dots-vertical" />
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <Link className="dropdown-item" to="#">
                                        <i className="ti ti-edit text-blue" />
                                        Edit
                                      </Link>
                                      <Link className="dropdown-item" to="#">
                                        <i className="ti ti-trash text-danger" />
                                        Delete
                                      </Link>
                                      <Link className="dropdown-item" to="#">
                                        <i className="ti ti-download text-info" />
                                        Download
                                      </Link>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="files-wrap">
                          <div className="row align-items-center">
                            <div className="col-md-8">
                              <div className="file-info">
                                <h4>Collier-Turner Proposal</h4>
                                <p>
                                  Send customizable quotes, proposals and
                                  contracts to close deals faster.
                                </p>
                                <div className="file-user">
                                  <ImageWithBasePath
                                    src="assets/img/profiles/avatar-22.jpg"
                                    alt="img"
                                  />
                                  <div>
                                    <p>
                                      <span>Owner</span> Vaughan
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 text-md-end">
                              <ul className="file-action">
                                <li>
                                  <span className="badge badge-tag badge-danger-light">
                                    Proposal
                                  </span>
                                </li>
                                <li>
                                  <span className="badge badge-tag bg-pending priority-badge">
                                    Draft
                                  </span>
                                </li>
                                <li>
                                  <div className="dropdown action-drop">
                                    <Link
                                      to="#"
                                      className="dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="ti ti-dots-vertical" />
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <Link className="dropdown-item" to="#">
                                        <i className="ti ti-edit text-blue" />
                                        Edit
                                      </Link>
                                      <Link className="dropdown-item" to="#">
                                        <i className="ti ti-trash text-danger" />
                                        Delete
                                      </Link>
                                      <Link className="dropdown-item" to="#">
                                        <i className="ti ti-download text-info" />
                                        Download
                                      </Link>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    {/* /Files */}
                    {/* Email */}
                    {/* <div className="tab-pane fade" id="email">
                      <div className="view-header">
                        <h4>Email</h4>
                        <ul>
                          <li>
                            <OverlayTrigger
                              placement="left"
                              overlay={
                                <Tooltip id="tooltip-left">
                                  There are no email accounts configured. Please
                                  configure your email account in order to
                                  Send/Create Emails.
                                </Tooltip>
                              }
                            >
                              <Link
                                to="#"
                                className="com-add"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                data-bs-custom-class="tooltip-dark"
                                data-bs-original-title="There are no email accounts configured. Please configure your email account in order to Send/Create Emails"
                              >
                                <i className="ti ti-circle-plus me-1" />
                                Create Email
                              </Link>
                            </OverlayTrigger>
                          </li>
                        </ul>
                      </div>
                      <div className="files-activity">
                        <div className="files-wrap">
                          <div className="row align-items-center">
                            <div className="col-md-8">
                              <div className="file-info">
                                <h4>Manage Emails</h4>
                                <p>
                                  You can send and reply to emails directly via
                                  this section.
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 text-md-end">
                              <ul className="file-action">
                                <li>
                                  <Link
                                    to="#"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#create_email"
                                  >
                                    Connect Account
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* /Email */}
                    {/* Family Members */}
                    <div className="tab-pane fade" id="familyMembers">
                      <div className="view-header">
                        <h4>Family Members</h4>
                        <Link
                          to="#"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#add_familyMember"
                        >
                          <i className="ti ti-plus" /> Add Family Member
                        </Link>
                      </div>
                      <div className="contact-activity">
                        <ul>
                          {familyOptions &&
                            familyOptions.length > 0 &&
                            familyOptions.map((eachMember) => (
                              <li
                                className="activity-wrap align-items-center gap-3"
                                key={eachMember?.id || eachMember?.name}
                              >
                                <span className="activity-icon bg-secondary-success">
                                  <i className="fas fa-user" />
                                </span>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                  <div className="activity-info ms-2 w-100 d-flex justify-content-around align-items-center">
                                    <div>
                                      <p
                                        className="text-capitalize"
                                        style={{ fontSize: "small" }}
                                      >
                                        <b>Name :</b> {eachMember?.name || ""}
                                      </p>
                                      <p style={{ fontSize: "small" }}>
                                        <b>Contact Number :</b>{" "}
                                        {eachMember?.contactNo || ""}
                                      </p>
                                    </div>
                                    <div>
                                      <p
                                        className="text-capitalize"
                                        style={{ fontSize: "small" }}
                                      >
                                        <b>Relation :</b>{" "}
                                        {eachMember?.familyMemberRelationType
                                          ?.name || "-"}
                                      </p>
                                      <p style={{ fontSize: "small" }}>
                                        <b>Date Of Birth :</b>{" "}
                                        {eachMember?.dateOfBirth
                                          ? moment(
                                              eachMember?.dateOfBirth
                                            ).format("DD-MM-YYYY")
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="act-dropdown">
                                    <Link
                                      to="#"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="ti ti-dots-vertical" />
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <Link
                                        to="#"
                                        className="dropdown-item d-flex justfy-content-start"
                                        data-bs-toggle="modal"
                                        data-bs-target="#add_familyMember"
                                        onClick={() =>
                                          onEditFamilyMemberModal(eachMember)
                                        }
                                      >
                                        <i className="ti ti-edit-circle p-1" />
                                        Edit
                                      </Link>
                                      <Link
                                        className="dropdown-item d-flex justfy-content-start"
                                        to="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delete_familyMember"
                                        onClick={() =>
                                          onDeleteFamilyMemberModal(eachMember)
                                        }
                                      >
                                        <i className="ti ti-trash text-danger p-1" />
                                        Delete
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    {/* /Family Members */}
                  </div>
                </div>
                {/* /Tab Content */}
              </div>
              {/* Add & Edit Family Member */}
              {selectedFamilyMembers && (
                <div
                  className="modal custom-modal"
                  id="add_familyMember"
                  role="dialog"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          {selectedFamilyMembers &&
                          Object.keys(selectedFamilyMembers).length
                            ? "Edit Family Member"
                            : "Add Family Member"}
                        </h5>
                        <button
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          ref={familymodalRef}
                        >
                          <i className="ti ti-x" />
                        </button>
                      </div>
                      <div className="modal-body p-0">
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            name: selectedFamilyMembers?.name || "",
                            relation: selectedFamilyMembers?.relation
                              ? {
                                  ...selectedFamilyMembers?.relation,
                                  label:
                                    selectedFamilyMembers
                                      ?.familyMemberRelationType?.name,
                                  value: selectedFamilyMembers?.publicId,
                                }
                              : "",
                            dateOfBirth: selectedFamilyMembers?.dateOfBirth
                              ? moment(
                                  selectedFamilyMembers?.dateOfBirth
                                ).format("YYYY-MM-DD")
                              : "",
                            contactNo: selectedFamilyMembers?.contactNo || "",
                            customFields:
                              selectedFamilyMembers?.customFields || [],
                          }}
                          validationSchema={familyModelSchema}
                          onSubmit={(values) => {
                            selectedFamilyMembers &&
                            Object.keys(selectedFamilyMembers).length > 0
                              ? updateFamilyMembers(values)
                              : addFamilyMembers(values);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <Form className="form" onSubmit={handleSubmit}>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-wrap">
                                    <label
                                      className="col-form-label"
                                      htmlFor="name"
                                    >
                                      Name
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="name"
                                      id="name"
                                      placeholder="Enter Name"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.name}
                                    />

                                    <CustomErrorMessage
                                      name="name"
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-wrap">
                                    <label
                                      className="col-form-label"
                                      htmlFor="name"
                                    >
                                      Relation
                                      <span className="text-danger">*</span>
                                    </label>
                                    <AsyncSelect
                                      id="relation"
                                      name="relation"
                                      placeholder="Select Relation"
                                      onChange={(e) =>
                                        setFieldValue("relation", e)
                                      }
                                      value={values?.relation}
                                      defaultOptions={relationOptions}
                                      onBlur={handleBlur}
                                      className="react-select"
                                      loadOptions={onSearchRelationDebounce}
                                      cacheOptions
                                    />
                                    <CustomErrorMessage
                                      name="relation"
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      Date of Birth
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      aria-label="With textarea"
                                      name="dateOfBirth"
                                      id="dateOfBirth"
                                      max={
                                        new Date().toISOString().split("T")[0]
                                      }
                                      value={values.dateOfBirth}
                                      onChange={(e) => {
                                        setDateOfBirthChange(true);
                                        setFieldValue(
                                          "dateOfBirth",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <CustomErrorMessage
                                      name="dateOfBirth"
                                      errors={errors}
                                      touched={touched}
                                    />
                                    {/* <div className="icon-form-end">
                                    <span className="form-icon">
                                      <i className="ti ti-calendar-event" />
                                    </span>
                                    <DatePicker
                                      className="form-control datetimepicker deals-details"
                                      selected={selectedDate}
                                      onChange={(date) => {
                                        setFieldValue("dateOfBirth", date);
                                        handleDateChange(date);
                                      }}
                                      dateFormat="dd-MM-yyyy"
                                    />
                                  </div> */}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      Contact Number{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.contactNo}
                                      type="text"
                                      name="contactNo"
                                      className="form-control"
                                    />
                                    <CustomErrorMessage
                                      name="contactNo"
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex align-items-center gap-5">
                                  <p
                                    className="mb-0"
                                    style={{ fontWeight: 500 }}
                                  >
                                    Fields :
                                  </p>

                                  <div
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                      borderRadius: "8px",
                                      background: "#e5f0ff",
                                    }}
                                  >
                                    <img
                                      src={plusIcon}
                                      alt="add"
                                      onClick={() => {
                                        let customFields =
                                          values?.customFields || [];
                                        customFields.push({
                                          name: "",
                                          value: "",
                                        });
                                        setFieldValue(
                                          "customFields",
                                          customFields
                                        );
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-12">
                                  {values.customFields &&
                                    values.customFields.length > 0 &&
                                    values.customFields.map((_, index) => {
                                      return (
                                        <div className="row mt-3" key={index}>
                                          <div className="form-group col-5">
                                            <div className="form-control-wrap">
                                              <input
                                                type="text"
                                                className="form-control"
                                                name={`customFields.${index}.name`}
                                                placeholder="Name"
                                                value={_.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                              />
                                            </div>
                                          </div>
                                          <div className="form-group col-5">
                                            <div className="form-control-wrap">
                                              <input
                                                type="text"
                                                className="form-control"
                                                name={`customFields.${index}.value`}
                                                placeholder="value"
                                                value={_.value}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                              />
                                              {/* <Select
                                              className="select"
                                              classNamePrefix="react-select"
                                              name={`customFields.${index}.type`}
                                              options={typeOptions}
                                              placeholder="Select Type"
                                              value={typeOptions.find(
                                                (option) =>
                                                  option.value === _.type
                                              )}
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `customFields.${index}.type`,
                                                  e.value
                                                )
                                              }
                                            /> */}
                                            </div>
                                          </div>

                                          <div
                                            className="grey-symbol-btn col-2"
                                            onClick={() => {
                                              let customFields =
                                                values?.customFields || [];
                                              customFields =
                                                customFields.filter(
                                                  (each, i) => i !== index
                                                );
                                              setFieldValue(
                                                "customFields",
                                                customFields
                                              );
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <img src={minusIcon} alt="remove" />
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                              <div className="submit-button text-end mt-3">
                                <Link
                                  to="#"
                                  // onClick={() =>
                                  //   setActivityTogglePopup(!activityTogglePopup)
                                  // }
                                  className="btn btn-light sidebar-close"
                                >
                                  Cancel
                                </Link>
                                <Button
                                  className="btn btn-primary"
                                  type="submit"
                                >
                                  {buttonLoading ? (
                                    <ButtonLoading />
                                  ) : selectedFamilyMembers &&
                                    Object.keys(selectedFamilyMembers)
                                      .length ? (
                                    "Edit"
                                  ) : (
                                    "Add"
                                  )}
                                </Button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Add & Edit Family Member */}
              {/* DeleteFamily Member */}
              {selectedFamilyMembers && (
                <div
                  className="modal custom-modal fade"
                  id="delete_familyMember"
                  role="dialog"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header border-0 m-0 justify-content-end">
                        <button
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => setSelectedFamilyMembers({})}
                        >
                          <i className="ti ti-x" />
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="success-message text-center">
                          <div className="success-popup-icon">
                            <i className="ti ti-trash-x" />
                          </div>
                          <h3>Remove Family Member?</h3>
                          <p className="del-info">
                            Are you sure you want to remove{" "}
                            <span
                              style={{
                                color: "#9d72ff",
                                textDecoration: "underline",
                              }}
                            >
                              {selectedFamilyMembers?.name || ""}
                            </span>
                            .
                          </p>
                          <div className="col-lg-12 text-center modal-btn">
                            <Link
                              to="#"
                              className="btn btn-light"
                              data-bs-dismiss="modal"
                              onClick={() => setSelectedFamilyMembers({})}
                            >
                              Cancel
                            </Link>
                            <Link
                              to="#"
                              className="btn btn-danger"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                deleteFamilyMembers();
                              }}
                            >
                              Yes, Delete it
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* DeleteFamily Member */}
              {/* /Contact Details */}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* Add Attachment */}
      {selectedAttachment && (
        <div
          className={
            documentModel ? "toggle-popup sidebar-popup" : "toggle-popup"
          }
        >
          <div className="sidebar-layout">
            <div className="sidebar-header">
              <h4>
                {selectedAttachment?.publicId
                  ? "Update Attachment"
                  : "Add Attachment"}
              </h4>
              <div
                className="sidebar-close toggle-btn"
                onClick={() => setDocumentModel(false)}
              >
                <i className="ti ti-x" />
              </div>
            </div>
            <div className="toggle-body">
              <div className="pro-create">
                <Formik
                  initialValues={{
                    userId: selectedAttachment?.publicId
                      ? selectedAttachment?.publicId
                      : empDetailsById
                      ? empDetailsById?.publicId
                      : "",
                    familyId: selectedAttachment?.familyMember
                      ? {
                          ...selectedAttachment?.familyMember,
                          label: selectedAttachment?.familyMember?.name,
                          value: selectedAttachment?.familyMember?.publicId,
                        }
                      : "",
                    documentTypeId: selectedAttachment?.documentType
                      ? {
                          ...selectedAttachment?.documentType,
                          label: selectedAttachment?.documentType?.name,
                          value: selectedAttachment?.documentType?.publicId,
                        }
                      : "",
                    documentNumber: selectedAttachment?.documentNumber
                      ? selectedAttachment?.documentNumber
                      : "",
                    issueDate: selectedAttachment?.issueDate
                      ? moment(selectedAttachment?.issueDate).format(
                          "YYYY-MM-DD"
                        )
                      : "",
                    expiryDate: selectedAttachment?.expiryDate
                      ? moment(selectedAttachment?.expiryDate).format(
                          "YYYY-MM-DD"
                        )
                      : "",
                    renewDate: selectedAttachment?.renewDate
                      ? moment(selectedAttachment?.renewDate).format(
                          "YYYY-MM-DD"
                        )
                      : "",
                    document: selectedAttachment?.document || [],
                    customInfo: selectedAttachment?.customInfo || {},
                  }}
                  validationSchema={
                    selectedAttachment && selectedAttachment?.publicId
                      ? ""
                      : attachmentSchema
                  }
                  onSubmit={(values) => {
                    selectedAttachment && selectedAttachment?.publicId
                      ? updateAttachment(values)
                      : addAttachment(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Form className="form" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label" htmlFor="name">
                              User
                              <span className="text-danger">*</span>
                            </label>
                            {/* <AsyncSelect
                            id="userId"
                            name="userId"
                            placeholder="Select User Id"
                            // onChange={(e) => {
                            //   setFieldValue("userId", e);
                            //   getFamilyOptions(e.publicId);
                            // }}
                            value={values?.userId}
                            // defaultOptions={userOptions}
                            className="react-select"
                            // loadOptions={onSearchUserDebounce}
                            cacheOptions
                            disabled
                          /> */}
                            <input
                              type="text"
                              className="form-control"
                              name="userId"
                              id="userId"
                              value={
                                empDetailsById ? empDetailsById?.empName : ""
                              }
                              disabled
                              // placeholder="Enter Document Number"
                              // onChange={handleChange}
                              // onBlur={handleBlur}
                              // value={values.documentNumber}
                            />

                            <CustomErrorMessage
                              name="userId"
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              className="col-form-label"
                              htmlFor="description"
                            >
                              Family
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <AsyncSelect
                              id="familyId"
                              name="familyId"
                              placeholder="Select Family Id"
                              onChange={(e) => setFieldValue("familyId", e)}
                              value={values?.familyId}
                              defaultOptions={familyOptions}
                              className="react-select"
                              loadOptions={onSearchFamilyDebounce}
                              cacheOptions
                            />
                            <CustomErrorMessage
                              name="familyId"
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-group">
                            <label className="col-form-label" htmlFor="name">
                              Remainder Type
                              <span className="text-danger">*</span>
                            </label>
                            <AsyncSelect
                              id="documentTypeId"
                              name="documentTypeId"
                              placeholder="Select Remainder Type"
                              onChange={(e) => {
                                setFieldValue("documentTypeId", e);
                                setelectedDocType(e);
                              }}
                              value={values?.documentTypeId}
                              defaultOptions={docTypeOptions}
                              className="react-select"
                              loadOptions={onSearchDocTypeDebounce}
                              cacheOptions
                            />

                            <CustomErrorMessage
                              name="documentTypeId"
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-group">
                            <label className="col-form-label" htmlFor="name">
                              Relation Number
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="documentNumber"
                              id="documentNumber"
                              placeholder="Enter Relation Number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.documentNumber}
                            />

                            <CustomErrorMessage
                              name="documentNumber"
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-group">
                            <label className="col-form-label" htmlFor="name">
                              Issue Date
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="issueDate"
                              id="issueDate"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.issueDate}
                            />

                            <CustomErrorMessage
                              name="issueDate"
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-group">
                            <label className="col-form-label" htmlFor="name">
                              Expiry Date
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="expiryDate"
                              id="expiryDate"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.expiryDate}
                            />

                            <CustomErrorMessage
                              name="expiryDate"
                              errors={errors}
                              touched={touched}
                            />
                          </div>
                        </div>
                        <div className="form-group mt-3">
                          <label className="col-form-label" htmlFor="name">
                            Renew Date
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            name="renewDate"
                            id="renewDate"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.renewDate}
                          />

                          <CustomErrorMessage
                            name="renewDate"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-5 mt-3">
                        <p className="mb-0" style={{ fontWeight: 500 }}>
                          Custom Info :
                        </p>

                        {/* <div
                        style={{
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          borderRadius: "8px",
                          background: "#e5f0ff",
                        }}
                      >
                        <img
                          src={plusIcon}
                          alt="add"
                          onClick={() => {
                            let customInfo = values?.customInfo || [];
                            customInfo.push({
                              name: "",
                              type: "",
                            });
                            setFieldValue("customInfo", customInfo);
                          }}
                        />
                      </div> */}
                      </div>
                      <div className="col-12">
                        {selectedDocType &&
                          selectedDocType?.fields.map((field, index) => (
                            <div className="row mt-3" key={index}>
                              <div className="form-group col-5">
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={field.name}
                                    disabled
                                  />
                                </div>
                              </div>
                              {field?.type === "text" && (
                                <div className="form-group col-5">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name={`customInfo.${field.name}`}
                                    placeholder="Enter Name"
                                    value={values.customInfo[field.name]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              )}
                              {field?.type === "date" && (
                                <div className="form-group col-5">
                                  <input
                                    type="date"
                                    className="form-control"
                                    name={`customInfo.${field.name}`}
                                    placeholder="Enter Name"
                                    value={values.customInfo[field.name]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              )}
                              {field?.type === "boolean" && (
                                <div className="col-5">
                                  <select
                                    className="form-select"
                                    name={`customInfo.${field.name}`}
                                    placeholder="Select"
                                    value={values.customInfo[field.name]}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </select>
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                      <div className="d-flex align-items-center gap-5 mt-3">
                        <p className="mb-0" style={{ fontWeight: 500 }}>
                          Documents :
                        </p>

                        <div className="grey-symbol-btn">
                          <img
                            src={plusIcon}
                            alt="add"
                            onClick={() => {
                              let document = values?.document || [];
                              document.push({
                                file: "",
                              });
                              setFieldValue("document", document);
                            }}
                          />
                        </div>
                      </div>
                      <CustomErrorMessage
                        name="document"
                        errors={errors}
                        touched={touched}
                      />
                      <div className="col-12">
                        {values.document &&
                          values.document.length > 0 &&
                          values.document?.map((_, index) => {
                            return (
                              <div className="row mt-3">
                                <div class="form-group col-5">
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="reg_content_sub_heading"
                                      name={`document.${index}.name`}
                                      placeholder="Label"
                                      value={_.name}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div class="form-group col-6">
                                  <div class="form-control-wrap">
                                    <input
                                      type="file"
                                      className="form-control"
                                      placeholder="Upload File"
                                      id="reg_content_sub_content"
                                      name={`document.${index}.file`}
                                      value={document[index]?.file}
                                      onChange={(e) =>
                                        setFieldValue(
                                          `document.${index}.file`,
                                          e.target.files[0]
                                        )
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="grey-symbol-btn col-1"
                                  onClick={() => {
                                    let document = values?.document || [];

                                    document = document.filter(
                                      (each, i) => i !== index
                                    );

                                    setFieldValue("document", document);
                                  }}
                                >
                                  <img src={minusIcon} alt="remove" />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      {/* <div className="col-md-12 mt-3">
                      <div className="form-wrap">
                        <div className="profile-upload">
                          <div className="profile-upload-img">
                            <span>
                              <i className="ti ti-photo" />
                            </span>
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-20.jpg"
                              alt="img"
                              className="preview1"
                            />
                            <button type="button" className="profile-remove">
                              <i className="ti ti-x" />
                            </button>
                          </div>
                          <div className="profile-upload-content">
                            <label className="profile-upload-btn">
                              <i className="ti ti-file-broken" /> Upload File
                              <input
                                name="files"
                                type="file"
                                multiple
                                onChange={(e) => {
                                  setFieldValue("document", e.target.files);
                                }}
                                accept="*"
                              />
                            </label>
                            <p>JPG, GIF or PNG. Max size of 800K</p>
                          </div>
                        </div>
                        <CustomErrorMessage
                          name="document"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div> */}

                      <div className="submit-button text-end mt-3">
                        <Button
                          onClick={() => setDocumentModel(false)}
                          className="btn btn-light sidebar-close"
                        >
                          Cancel
                        </Button>
                        <Button className="btn btn-primary" type="submit">
                          {buttonLoading ? (
                            <ButtonLoading />
                          ) : selectedAttachment.publicId ? (
                            "Update"
                          ) : (
                            "Add"
                          )}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Add Attachment */}
      {/* Delete Attachment */}
   
      {selectedAttachmentDelete && attachmentDeleteModel && (
        <div
          className="modal custom-modal fade"
          id="delete_attachment"
          aria-labelledby="delete_attachmentLabel"
          aria-hidden={attachmentDeleteModel ? "false" : "true"}
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0 m-0 justify-content-end">
                <button
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setSelectedAttachmentDelete()}
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <div className="modal-body">
                <div className="success-message text-center">
                  <div className="success-popup-icon">
                    <i className="ti ti-trash-x" />
                  </div>
                  <h3>Remove Attachment?</h3>
                  <p className="del-info">
                    Are you sure you want to remove{" "}
                    <span
                      style={{
                        color: "#9d72ff",
                        textDecoration: "underline",
                      }}
                    >
                      {selectedAttachmentDelete?.documentNumber || ""}
                    </span>
                    .
                  </p>
                  <div className="col-lg-12 text-center modal-btn">
                    <Link
                      to="#"
                      className="btn btn-light"
                      data-bs-dismiss="modal"
                      onClick={() => setSelectedAttachmentDelete()}
                    >
                      Cancel
                    </Link>
                    <div
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        deleteAttachment();
                      }}
                    >
                      Yes, Delete it
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* /Delete Attachment */}
      {/* Add New Deals */}
      <div
        className={
          activityToggle ? "toggle-popup sidebar-popup" : "toggle-popup"
        }
      >
        <div className="sidebar-layout">
          <div className="sidebar-header">
            <h4>Add New Deals</h4>
            <Link
              to="#"
              className="sidebar-close toggle-btn"
              onClick={() => setActivityTogglePopup(!activityToggle)}
            >
              <i className="ti ti-x" />
            </Link>
          </div>
          <div className="toggle-body">
            <div className="pro-create">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Deal Name <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Pipeine <span className="text-danger">*</span>
                      </label>

                      <Select
                        className="select"
                        options={salestypelist}
                        placeholder="Choose"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Status <span className="text-danger">*</span>
                      </label>

                      <Select
                        className="select2"
                        options={status}
                        placeholder="Choose"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Deal Value<span className="text-danger"> *</span>
                      </label>
                      <input className="form-control" type="text" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Currency <span className="text-danger">*</span>
                      </label>

                      <Select
                        className="select"
                        options={optionssymbol}
                        placeholder="Select"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Period <span className="text-danger">*</span>
                      </label>
                      <input className="form-control" type="text" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Period Value <span className="text-danger">*</span>
                      </label>
                      <input className="form-control" type="text" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Contact <span className="text-danger">*</span>
                      </label>
                      <input
                        className="input-tags form-control"
                        type="text"
                        data-role="tagsinput"
                        name="Label"
                        defaultValue="Jack, Darlee Robertson"
                      />
                    </div>
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Project <span className="text-danger">*</span>
                      </label>
                      <input
                        className="input-tags form-control"
                        type="text"
                        data-role="tagsinput"
                        name="Label"
                        defaultValue="Divine dran"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Due Date <span className="text-danger">*</span>
                      </label>
                      <div className="icon-form">
                        <span className="form-icon">
                          <i className="ti ti-calendar-check" />
                        </span>

                        <DatePicker
                          className="form-control datetimepicker deals-details"
                          selected={selectedDate}
                          onChange={handleDateChange}
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Expected Closing Date{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="icon-form">
                        <span className="form-icon">
                          <i className="ti ti-calendar-check" />
                        </span>
                        <DatePicker
                          className="form-control datetimepicker deals-details"
                          selected={selectedDate2}
                          onChange={handleDateChange2}
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Assignee <span className="text-danger">*</span>
                      </label>
                      <input
                        className="input-tags form-control"
                        type="text"
                        data-role="tagsinput"
                        name="Label"
                        defaultValue="James"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Follow Up Date <span className="text-danger">*</span>
                      </label>
                      <div className="icon-form">
                        <span className="form-icon">
                          <i className="ti ti-calendar-check" />
                        </span>

                        <DatePicker
                          className="form-control datetimepicker deals-details"
                          selected={selectedDate4}
                          onChange={handleDateChange4}
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Source <span className="text-danger">*</span>
                      </label>

                      <Select
                        className="select"
                        options={socialMedia}
                        placeholder="Select"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Tags <span className="text-danger">*</span>
                      </label>
                      <TagsInput
                        // className="input-tags form-control"
                        value={owner}
                        onChange={setOwner}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Priority <span className="text-danger">*</span>
                      </label>

                      <Select
                        className="select"
                        options={priorityList}
                        placeholder="Select"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Description <span className="text-danger">*</span>
                      </label>
                      <div className="summernote" />
                    </div>
                  </div>
                </div>
                <div className="submit-button text-end">
                  <Link to="#" className="btn btn-light sidebar-close">
                    Cancel
                  </Link>
                  <Link to="#" className="btn btn-primary">
                    Create
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add New Deals */}
      {/* /Page Wrapper */}
      <div
        className="modal custom-modal fade modal-padding"
        id="add_notes"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add New Notes</h5>
              <button
                type="button"
                className="btn-close position-static"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <form>
                <div className="form-wrap">
                  <label className="col-form-label">
                    Title <span className="text-danger"> *</span>
                  </label>
                  <input className="form-control" type="text" />
                </div>
                <div className="form-wrap">
                  <label className="col-form-label">
                    Note <span className="text-danger"> *</span>
                  </label>
                  <textarea
                    className="form-control"
                    rows={4}
                    defaultValue={""}
                  />
                </div>
                <div className="form-wrap">
                  <label className="col-form-label">
                    Attachment <span className="text-danger"> *</span>
                  </label>
                  <div className="drag-attach">
                    <input type="file" />
                    <div className="img-upload">
                      <i className="ti ti-file-broken" />
                      Upload File
                    </div>
                  </div>
                </div>
                <div className="form-wrap">
                  <label className="col-form-label">Uploaded Files</label>
                  <div className="upload-file">
                    <h6>Projectneonals teyys.xls</h6>
                    <p>4.25 MB</p>
                    <div className="progress">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{ width: "25%" }}
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                    <p className="black-text">45%</p>
                  </div>
                  <div className="upload-file upload-list">
                    <div>
                      <h6>tes.txt</h6>
                      <p>4.25 MB</p>
                    </div>
                    <Link to="#" className="text-danger">
                      <i className="ti ti-trash-x" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-12 text-end modal-btn">
                  <Link
                    to="#"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Confirm
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Create Call Log */}
      <div
        className="modal custom-modal fade modal-padding"
        id="create_call"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Create Call Log</h5>
              <button
                type="button"
                className="btn-close position-static"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Status <span className="text-danger"> *</span>
                      </label>

                      <Select
                        className="select"
                        options={statusList}
                        placeholder="Choose"
                        classNamePrefix="react-select"
                      />
                    </div>
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Follow Up Date <span className="text-danger"> *</span>
                      </label>
                      <div className="icon-form">
                        <span className="form-icon">
                          <i className="ti ti-calendar-check" />
                        </span>
                        <DatePicker
                          className="form-control datetimepicker deals-details"
                          selected={selectedDate1}
                          onChange={handleDateChange1}
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                    </div>
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Note <span className="text-danger"> *</span>
                      </label>
                      <textarea
                        className="form-control"
                        rows={4}
                        placeholder="Add text"
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-wrap">
                      <label className="checkboxs">
                        <input type="checkbox" />
                        <span className="checkmarks" /> Create a followup task
                      </label>
                    </div>
                    <div className="text-end modal-btn">
                      <Link
                        to="#"
                        className="btn btn-light"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </Link>
                      <Link
                        className="btn btn-primary"
                        to="#"
                        data-bs-dismiss="modal"
                      >
                        Confirm
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Create Call Log */}
      {/* Add Compose */}
      <div className="modal custom-modal fade" id="add_compose" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Compose</h5>
              <button
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <div className="modal-body p-0">
              <form action="#">
                <div className="form-wrap">
                  <input
                    type="email"
                    placeholder="To"
                    className="form-control"
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <input
                        type="email"
                        placeholder="Cc"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-wrap">
                      <input
                        type="email"
                        placeholder="Bcc"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-wrap">
                  <input
                    type="text"
                    placeholder="Subject"
                    className="form-control"
                  />
                </div>
                <div className="form-wrap">
                  <DefaultEditor className="summernote" />
                </div>
                <div className="form-wrap">
                  <div className="text-center">
                    <button className="btn btn-primary me-1">
                      <span>Send</span>
                      <i className="fa-solid fa-paper-plane ms-1" />
                    </button>
                    <button className="btn btn-primary me-1" type="button">
                      <span>Draft</span>{" "}
                      <i className="fa-regular fa-floppy-disk ms-1" />
                    </button>
                    <button className="btn btn-primary me-1" type="button">
                      <span>Delete</span>{" "}
                      <i className="fa-regular fa-trash-can ms-1" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Compose */}
      {/* Delete Contact */}
      <div
        className="modal custom-modal fade"
        id="delete_contact"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 m-0 justify-content-end">
              <button
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setSelectedEmployee({})}
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon">
                  <i className="ti ti-trash-x" />
                </div>
                <h3>Remove Employee?</h3>
                <p className="del-info">
                  Are you sure you want to remove{" "}
                  <span
                    style={{ color: "#9d72ff", textDecoration: "underline" }}
                  >
                    {selectedEmployee?.empName
                      ? selectedEmployee?.empName.split("-").join(" ")
                      : ""}
                  </span>
                  .
                </p>
                <div className="col-lg-12 text-center modal-btn">
                  <Link
                    to="#"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                    onClick={() => setSelectedEmployee({})}
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      deleteEmployee();
                    }}
                  >
                    Yes, Delete it
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Delete Contact */}
      {/* Edit Contact */}
      {empDetailsById && Object.keys(empDetailsById).length && (
        <div
          className={
            edit || activityToggleTwo
              ? "toggle-popup1 sidebar-popup"
              : "toggle-popup1"
          }
        >
          <div className="sidebar-layout">
            <div className="sidebar-header">
              <h4>Edit Employee</h4>
              <Link
                to={`${route.contactsDetails}?user_id=${userId}`}
                className="sidebar-close1 toggle-btn"
                onClick={() => setActivityTogglePopupTwo(!activityToggleTwo)}
              >
                <i className="ti ti-x" />
              </Link>
            </div>
            <div className="toggle-body">
              <div className="pro-create">
                <Formik
                  initialValues={{
                    firstName: empDetailsById?.empName
                      ? empDetailsById.empName.split("-")[0]
                      : "",
                    lastName: empDetailsById?.empName
                      ? empDetailsById.empName.split("-")[1]
                      : "",
                    contactNumber: empDetailsById?.empContact || "",
                    userName: empDetailsById?.empContact || "",
                    employeeCode: empDetailsById?.empCode || "",
                    email: empDetailsById?.empEmail || "",
                    empProfilePhoto: empDetailsById?.empProfilePhoto || "",
                    joiningDate: empDetailsById?.joiningDate
                      ? moment(empDetailsById?.joiningDate).format("YYYY-MM-DD")
                      : null,
                    discipline:
                      empDetailsById?.deptartment &&
                      Object.keys(empDetailsById?.deptartment).length
                        ? {
                            label: empDetailsById?.deptartment?.deptName,
                            value: empDetailsById?.deptartment?.publicId,
                          }
                        : "",
                    designation:
                      empDetailsById?.designation &&
                      Object.keys(empDetailsById?.designation).length
                        ? {
                            label: empDetailsById?.designation?.desgName,
                            value: empDetailsById?.designation?.publicId,
                          }
                        : "",
                    role:
                      empDetailsById?.userRole &&
                      Object.keys(empDetailsById?.userRole).length
                        ? {
                            label: empDetailsById?.userRole?.role?.role,
                            value: empDetailsById?.userRole?.publicId,
                          }
                        : "",
                    reportingManager:
                      empDetailsById?.userRole &&
                      Object.keys(empDetailsById?.userRole).length
                        ? {
                            label:
                              empDetailsById?.reportingManager
                                ?.reportingManager,
                            value: empDetailsById?.reportingManager?.publicId,
                          }
                        : "",
                  }}
                  // validationSchema={addEmployeeSchema}
                  onSubmit={(values) => {
                    updateEmployee(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Form>
                      <div className="accordion-lists" id="list-accord">
                        {/* Basic Info */}
                        <div className="user-accordion-item">
                          <Link
                            to="#"
                            className="accordion-wrap"
                            data-bs-toggle="collapse"
                            data-bs-target="#basic"
                          >
                            <span>
                              <i className="ti ti-user-plus" />
                            </span>
                            Basic Info
                          </Link>
                          <div
                            className="accordion-collapse collapse show"
                            id="basic"
                            data-bs-parent="#list-accord"
                          >
                            <div className="content-collapse">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-wrap">
                                    <div className="profile-upload">
                                      <div className="profile-upload-img">
                                        {/* <span>
                                          <i className="ti ti-photo" />
                                        </span> */}
                                        <img
                                          src={
                                            values?.empProfilePhoto
                                              ? `${fileBasicPath}${values?.empProfilePhoto}`
                                              : "assets/img/profiles/avatar-20.jpg"
                                          }
                                          alt="profile"
                                          style={{ width: "100%" }}
                                        />
                                        <button
                                          type="button"
                                          className="profile-remove"
                                        >
                                          <i className="ti ti-x" />
                                        </button>
                                      </div>
                                      <div className="profile-upload-content">
                                        <label className="profile-upload-btn">
                                          <i className="ti ti-file-broken" />{" "}
                                          Upload File
                                          <input
                                            type="file"
                                            className="input-img"
                                          />
                                        </label>
                                        <p>JPG, GIF or PNG. Max size of 800K</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      First Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.firstName}
                                      type="text"
                                      name="firstName"
                                      className="form-control"
                                    />
                                    <CustomErrorMessage
                                      name="firstName"
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      Last Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.lastName}
                                      type="text"
                                      name="lastName"
                                      className="form-control"
                                    />
                                    <CustomErrorMessage
                                      name="lastName"
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      Contact Number{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.contactNumber}
                                      type="text"
                                      name="contactNumber"
                                      className="form-control"
                                    />
                                    <CustomErrorMessage
                                      name="contactNumber"
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      Email{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.email}
                                      type="email"
                                      name="email"
                                      className="form-control"
                                    />
                                    <CustomErrorMessage
                                      name="email"
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      Employee Code{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.employeeCode}
                                      type="text"
                                      name="employeeCode"
                                      className="form-control"
                                    />
                                    <CustomErrorMessage
                                      name="employeeCode"
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      Joining Date{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      aria-label="With textarea"
                                      name="joiningDate"
                                      id="joiningDate"
                                      value={values.joiningDate}
                                      onChange={handleChange}
                                    />
                                    <CustomErrorMessage
                                      name="joiningDate"
                                      errors={errors}
                                      touched={touched}
                                    />
                                    {/* <div className="icon-form-end">
                                      <span className="form-icon">
                                        <i className="ti ti-calendar-event" />
                                      </span>
                                      <DatePicker
                                        className="form-control datetimepicker deals-details"
                                        selected={selectedDate}
                                        onChange={(date) => {
                                          setFieldValue("joiningDate", date);
                                          handleDateChange(date);
                                        }}
                                        dateFormat="dd-MM-yyyy"
                                      />
                                    </div> */}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      Discipline{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <AsyncSelect
                                      id="discipline"
                                      name="discipline"
                                      placeholder="Select Department"
                                      onChange={(option) => {
                                        setFieldValue("discipline", option);
                                      }}
                                      value={values?.discipline}
                                      defaultOptions={documentDepartments}
                                      className="react-select"
                                      loadOptions={onSearchdDeptDebounce}
                                      cacheOptions
                                    />
                                    <CustomErrorMessage
                                      name="discipline"
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      Designation{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <AsyncSelect
                                      id="designation"
                                      name="designation"
                                      placeholder="Select Designation"
                                      onChange={(option) =>
                                        setFieldValue("designation", option)
                                      }
                                      value={values?.designation}
                                      defaultOptions={documentDesignations}
                                      className="react-select"
                                      loadOptions={onSearchdDesigDebounce}
                                      cacheOptions
                                    />
                                    <CustomErrorMessage
                                      name="designation"
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      Role{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <AsyncSelect
                                      id="role"
                                      name="role"
                                      placeholder="Select Role"
                                      onChange={(option) =>
                                        setFieldValue("role", option)
                                      }
                                      value={values?.role}
                                      defaultOptions={accessRoles}
                                      className="react-select"
                                      loadOptions={onSearchdRoleDebounce}
                                      cacheOptions
                                    />
                                    {/* <Select
                                      classNamePrefix="react-select"
                                      options={accessRoles}
                                      onChange={(option) =>
                                        setFieldValue("discipline", option)
                                      }
                                      placeholder="Select an option"
                                    /> */}
                                    <CustomErrorMessage
                                      name="role"
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                </div>
                                {/* <div className="col-md-6">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      User Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="username"
                                      className="form-control"
                                    />
                                    <CustomErrorMessage
                                      name="username"
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </div>
                                </div> */}
                                <div className="col-md-6">
                                  <div className="form-wrap">
                                    <label className="col-form-label">
                                      Reporting Manager{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <AsyncSelect
                                      id="reportingManager"
                                      name="reportingManager"
                                      placeholder="Select Reporting Manager"
                                      onChange={(option) =>
                                        setFieldValue(
                                          "reportingManager",
                                          option
                                        )
                                      }
                                      value={values?.reportingManager}
                                      defaultOptions={reportingMagers}
                                      className="react-select"
                                      loadOptions={
                                        onSearchdReprotManagerDebounce
                                      }
                                      cacheOptions
                                    />
                                    <CustomErrorMessage
                                      name="reportingManager"
                                      errors={errors}
                                      touched={touched}
                                    />
                                    {/* <SelectWithImage employees={employees} /> */}
                                  </div>
                                </div>
                                {/* Other form fields */}
                                {/* <div className="col-md-6">
                                    <div className="form-wrap">
                                      <label className="col-form-label">
                                        Password{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        type="password"
                                        name="password"
                                        className="form-control"
                                      />
                                      <CustomErrorMessage
                                        name="password"
                                        errors={errors}
                                        touched={touched}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-wrap">
                                      <label className="col-form-label">
                                        Confirm Password{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.confirmPassword}
                                        type="password"
                                        name="confirmPassword"
                                        className="form-control"
                                      />
                                      <CustomErrorMessage
                                        name="confirmPassword"
                                        errors={errors}
                                        touched={touched}
                                      />
                                    </div>
                                  </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Basic Info */}
                        {/* Access */}
                        {/* <div className="user-accordion-item">
                          <Link
                            to="#"
                            className="accordion-wrap collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#access"
                          >
                            <span>
                              <i className="ti ti-accessible" />
                            </span>
                            Access
                          </Link>
                          <div
                            className="accordion-collapse collapse"
                            id="access"
                            data-bs-parent="#list-accord"
                          >
                            <div className="content-collapse">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="radio-wrap form-wrap">
                                    <label className="col-form-label">
                                      Visibility
                                    </label>
                                    <div className="d-flex flex-wrap">
                                      <div className="radio-btn">
                                        <input
                                          type="radio"
                                          className="status-radio"
                                          id="public"
                                          name="visible"
                                        />
                                        <label htmlFor="public">Public</label>
                                      </div>
                                      <div className="radio-btn">
                                        <input
                                          type="radio"
                                          className="status-radio"
                                          id="private"
                                          name="visible"
                                        />
                                        <label htmlFor="private">Private</label>
                                      </div>
                                      <div
                                        className="radio-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#access_view"
                                      >
                                        <input
                                          type="radio"
                                          className="status-radio"
                                          id="people"
                                          name="visible"
                                        />
                                        <label htmlFor="people">
                                          Select People
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="radio-wrap">
                                    <label className="col-form-label">
                                      Status
                                    </label>
                                    <div className="d-flex flex-wrap">
                                      <div className="radio-btn">
                                        <input
                                          type="radio"
                                          className="status-radio"
                                          id="active"
                                          name="status"
                                          defaultChecked
                                        />
                                        <label htmlFor="active">Active</label>
                                      </div>
                                      <div className="radio-btn">
                                        <input
                                          type="radio"
                                          className="status-radio"
                                          id="inactive"
                                          name="status"
                                        />
                                        <label htmlFor="inactive">
                                          Inactive
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        {/* /Access */}

                        <div className="col-12">
                          <div className="form-wrap">
                            <Button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? (
                                <ButtonLoading />
                              ) : (
                                "Edit Employee"
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* /Edit Contact */}
    </>
  );
};

export default ContactDetails;
