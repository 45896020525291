import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { allRoutes } from "../../../assets/routes/allRoutes";
import CollapseHeader from "../../../assets/common/CollapseHeader";
import MainServices from "../../../services/MainServices";
import PageLoader from "../../../assets/loaders/PageLoading";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import useTheme from "../../../hooks/useTheme";
import { Formik } from "formik";
import { notifySuccess, notifyWarn } from "../../../assets/elements/Toast";
import { Form, Button } from "react-bootstrap";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import Select from "react-select";
import plusIcon from "../../../assets/images/icons/add-icon.svg";
import minusIcon from "../../../assets/images/icons/minus-icon.svg";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import AsyncSelect from "react-select/async";
import useAuth from "../../../hooks/useAuth";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import ImageWithBasePath from "../../../assets/images/ImageWithBasicPath";
import moment from "moment";
import useConfig from "../../../hooks/useConfig";

const DocumentDetails = () => {
  const route = allRoutes;
  const [buttonLoading, setButtonLoading] = useState(0);
  const [documentsDetails, setDocumentType] = useState({});
  const [pageLoading, setPageLoading] = useState(0);
  const {
    getDocumentByIdMaster,
    updateDocuments,
    fileUpload,
    getDocumentMasters,
    addDocumentMaster,
    getFamilyMember,
    getDocumentType,
    getEmployeeMasters,
  } = MainServices();
  const location = useLocation();
  const publicId = new URLSearchParams(location.search).get("publicId");
  const [editInitiated, setEditInitiated] = useState(false);
  const edit = new URLSearchParams(location.search).get("edit");
  const [documentModel, setDocumentModel] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [familyOptions, setFamilyOptions] = useState([]);
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const [selectedDocType, setSelectedDocType] = useState("");
  const { user } = useAuth();
  const { deBounce } = TimingFunctions();
  const [documentDetails, setDocumentsDetails] = useState({});
  const { fileBasicPath } = useConfig();
  const [errorsOnSubmit, setErrorsOnSubmit] = useState([]);

  const getViewerUrl = (url) =>
    `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
      url
    )}`;

  const relationTypeSchema = Yup.object().shape({
    userId: Yup.object().required("User is Required"),
    // familyId: Yup.object().required("Family is Required"),
    documentTypeId: Yup.object().required("Remainder Type is Required"),
    // documentNumber: Yup.string()
    //   .transform((value) => value.trim())
    //   .required("Remainder Number is Required"),
    // issueDate: Yup.string().required("Issue Date is Required"),
    expiryDate: Yup.string().required("Expiry Date is Required"),
    // renewDate: Yup.string().required("Renew Date is Required"),
    // document: Yup.mixed().test("fileType", "Remainder is required", (value) => {
    //   return value && value.length > 0;
    // }),
  });

  useEffect(() => {
    if (publicId) {
      getDocDetailsById();
      getDocumentTypeOptions();
      getUserOptions();
    }
  }, [publicId]);

  const getDocDetailsById = async () => {
    setPageLoading(true);
    const details = {};
    await axios
      .all([getDocumentByIdMaster(details, publicId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let documentTypeDetails = resData.data ? resData?.data : {};
            const newDocuments = documentTypeDetails?.document
              ? documentTypeDetails.document.map((each) =>
                  typeof each === "string" ? JSON.parse(each) : each
                )
              : [];

            documentTypeDetails.document = newDocuments;
            // documentTypeDetails.fields = Object.keys(documentTypeDetails?.customInfo).map((field) => {
            //   let type = "";

            //   if (typeof documentTypeDetails?.customInfo[field] === "string") {
            //     type = "text";
            //   } else if (typeof documentTypeDetails?.customInfo[field] === "boolean") {
            //     type = "boolean";
            //   } else if (typeof documentTypeDetails?.customInfo[field] instanceof Date) {
            //     type = "date";
            //   }

            //   return {
            //     name: field,
            //     type: type,
            //   };
            // });
            setDocumentType(documentTypeDetails);
            // setDocumentsDetails(documentDetails);

            if (edit === "true" && !editInitiated) {
              onUpdatedocumentType(documentTypeDetails);
              setEditInitiated(true);
            }
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Remainder Type Details");
      });
  };

  const getFamilyOptions = async (publicId, filters, search) => {
    const details = {
      filters: [
        { key: "userId", eq: publicId },
        { key: "status", eq: "approved" },
        { key: "recordStatus", eq: "active" },
        ...(filters ? filters : []),
      ],
    };
    let results = await axios
      .all([getFamilyMember(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let familyList = resData?.data || [];
            familyList =
              familyList && familyList.length
                ? familyList.map((each) => {
                    return {
                      ...each,
                      label: each?.name || "",
                      value: each?.publicId || "",
                    };
                  })
                : [];

            familyList.unshift({ label: "Select Family Id", value: "" });
            !search && setFamilyOptions(familyList);
            return familyList;
          } else {
            if (message) notifyWarn(message);
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res.message) console.log("An error occurred in getting Family");
      });
    return results;
  };

  const getDocumentTypeOptions = async (filters, search) => {
    const details = {
      filters: [
        { key: "status", eq: "approved" },
        { key: "recordStatus", eq: "active" },
        ...(filters ? filters : []),
      ],
    };
    if (docTypeOptions?.publicId) {
      details.filters.push({ key: "name", eq: docTypeOptions.publicId });
    }
    let results = await axios
      .all([getDocumentType(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let docTypeList = resData?.data?.data || [];
            docTypeList =
              docTypeList && docTypeList.length
                ? docTypeList.map((each) => {
                    return {
                      ...each,
                      label: each?.name || "",
                      value: each?.publicId || "",
                    };
                  })
                : [];

            docTypeList.unshift({ label: "Select Remainder Type", value: "" });
            !search && setDocTypeOptions(docTypeList);
            return docTypeList;
          } else {
            if (message) notifyWarn(message);
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in getting Remainder Type");
      });
    return results;
  };

  const getUserOptions = async (filters, search) => {
    const details = {
      filters: [
        { key: "status", eq: "approved" },
        { key: "recordStatus", eq: "active" },
        ...(filters ? filters : []),
      ],
    };
    let results = await axios
      .all([getEmployeeMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let userList = resData?.data || [];
            userList =
              userList && userList.length
                ? userList.map((each) => {
                    return {
                      ...each,
                      label: each?.empName || "",
                      value: each?.publicId || "",
                    };
                  })
                : [];

            userList.unshift({ label: "Select User Id", value: "" });
            !search && setUserOptions(userList);
            return userList;
          } else {
            if (message) notifyWarn(message);
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res.message) console.log("An error occurred in getting User Id");
      });
    return results;
  };

  const updateDocumentTypeMaster = async (values) => {
    setButtonLoading(true);
    // let files = Array.isArray(values.document) ? values.document : [];
    // let logoFileURL = "";
    // if (files.length > 0 && values.document) {
    //   let logoUploadResponse = await fileUpload(files, user.token);
    //   logoFileURL = logoUploadResponse?.data?.fileName;
    // }

    // let documents = logoFileURL ? logoFileURL.split(",") : [];
    // if (documentsDetails) {
    //   let prevDocs = documentsDetails.document || [];
    //   documents.concat(prevDocs);
    // }
    let toolFileURL = "";
    if (values?.document && Array.isArray(values.document)) {
      for (let i = 0; i < values.document.length; i++) {
        if (typeof values.document[i].file !== "string") {
          let files = values.document[i].file || [];
          try {
            let toolUploadResponse = await fileUpload(files, user.token);
            toolFileURL = toolUploadResponse?.data?.fileName;
            values.document[i].file = toolFileURL;

            if (!toolFileURL) {
              setErrorsOnSubmit((oldValues) => [
                ...oldValues,
                ...(toolUploadResponse?.details?.map(
                  (item) => `${item.name} ${item.message}`
                ) || []),
              ]);

              if (toolUploadResponse?.message === "Network Error") {
                setErrorsOnSubmit((oldValues) => [
                  ...oldValues,
                  "File size too large",
                ]);
              }

              return;
            }
          } catch (error) {
            setErrorsOnSubmit((oldValues) => [
              ...oldValues,
              "An error occurred during file upload.",
            ]);

            return;
          }
        }
      }
    } else {
      setErrorsOnSubmit((oldValues) => [
        ...oldValues,
        "Instrument images data is invalid.",
      ]);
    }
    const details = {
      userId: values?.userId?.publicId,
      familyId: values?.familyId?.publicId,
      documentTypeId: values?.documentTypeId?.publicId,
      documentNumber: values?.documentNumber,
      issueDate: values?.issueDate,
      expiryDate: values?.expiryDate,
      renewDate: values?.renewDate,
      document: values?.document,
      customInfo: JSON.stringify(values.customInfo),
      description: values?.description,
    };

    await axios
      .all([
        updateDocuments(
          details,
          documentsDetails?.publicId,
          documentsDetails?.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getDocDetailsById();
            setDocumentModel(false);
            notifySuccess("Remainder Updated Successfully");
            setButtonLoading(false);
          } else {
            notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in update Remainder");
      });
  };

  //functions
  const onUpdatedocumentType = (selected) => {
    if (selected) {
      setDocumentType(selected);
    }
    setDocumentModel(!documentModel);
  };

  const onSearchUserDebounce = (inputValue, callback) => {
    deBounce(() => onSearchUser(inputValue, callback), 1000);
  };

  const onSearchUser = async (inputValue, callback) => {
    if (!inputValue) {
      callback(userOptions);
      return;
    }

    let filter = [
      {
        key: "empName",
        iLike: inputValue,
      },
    ];
    let options = await getUserOptions(filter, true);
    callback(options);
  };
  const onSearchFamilyDebounce = (inputValue, callback) => {
    deBounce(() => onSearchFamily(inputValue, callback), 1000);
  };

  const onSearchFamily = async (inputValue, callback) => {
    if (!inputValue) {
      callback(familyOptions);
      return;
    }

    let filter = [
      {
        key: "name",
        iLike: inputValue,
      },
    ];
    let options = await getFamilyOptions(filter, true);
    callback(options);
  };
  const onSearchDocTypeDebounce = (inputValue, callback) => {
    deBounce(() => onSearchDocType(inputValue, callback), 1000);
  };

  const onSearchDocType = async (inputValue, callback) => {
    if (!inputValue) {
      callback(docTypeOptions);
      return;
    }

    let filter = [
      {
        key: "name",
        iLike: inputValue,
      },
    ];
    let options = await getDocumentTypeOptions(filter, true);
    callback(options);
  };
  const getFileExtension = (filePath) => {
    return filePath.split(".").pop().toLowerCase();
  };

  const renderFile = (filePath) => {
    const extension = getFileExtension(filePath);

    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "svg":
        return <img src={filePath} alt="File" />;

      case "txt":
      case "md":
        // Fetch and render text content
        return (
          <pre>
            {fetch(filePath)
              .then((response) => response.text())
              .then((text) => text)}
          </pre>
        );

      case "xlsx":
      case "docx":
      case "pdf":
      case "doc":
      case "ppt":
      case "pptx":
        // Render XLSX file using Google Viewer
        const officeViewerURL = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
          filePath
        )}`;
        // const googleViewerURL = `https://docs.google.com/viewer?url=${encodeURIComponent(
        //   filePath
        // )}&embedded=true`;
        return (
          <iframe
            src={officeViewerURL}
            title="File"
            width="100%"
            height="700px"
          />
        );
      // Add more cases as needed
      default:
        return <div>Unsupported file type</div>;
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-10">
                    <h4 className="page-title">Remainder Details</h4>
                  </div>
                  <div className="col-sm-2 text-sm-start">
                    <div className="head-icons">
                      <CollapseHeader />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="contact-head">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <ul className="contact-breadcrumb">
                      <li>
                        <Link to={route.documents}>
                          <i className="ti ti-arrow-narrow-left" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9">
            <div className="contact-tab-wrap">
              <ul className="contact-nav nav">
                <li>
                  <Link
                    to="#"
                    data-bs-toggle="tab"
                    data-bs-target="#preview"
                    className="active"
                  >
                    <i className="ti ti-file" />
                    Preview
                  </Link>
                </li>

                <li>
                  <Link to="#" data-bs-toggle="tab" data-bs-target="#calls">
                    <i class="fas fa-users" />
                    Properties
                  </Link>
                </li>
              </ul>
            </div>
            {/* Tab Content */}
            <div className="contact-tab-view">
              <div className="tab-content pt-0">
                {/* Family Members */}
                <div className="tab-pane active show" id="preview">
                  <div className="view-header"></div>
                  {pageLoading ? (
                    <PageLoader />
                  ) : documentsDetails &&
                  documentsDetails.document &&
                  documentsDetails.document.length > 0 ? ( 
                    <div className="contact-activity">
                      <div className="contact-tab-wrap">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                          {documentsDetails.document.map((doc, index) => (
                            <li key={index} className="nav-item">
                              <Link
                                to="#"
                                data-bs-toggle="tab"
                                data-bs-target={`#doc${index + 1}`}
                                className={index === 0 ? "active" : ""}
                              >
                                {doc?.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          {documentsDetails.document.map((doc, index) => (
                            <div
                              key={index}
                              className={`tab-pane fade ${
                                index === 0 ? "show active" : ""
                              }`}
                              id={`doc${index + 1}`}
                            >
                              <div className="view-header"></div>
                              {renderFile(fileBasicPath + doc?.file)}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="no-data-found-main">No Documents Found</div>
                  )}
                </div>
                {/* Calls */}
                <div className="tab-pane fade" id="calls">
                  <div className="view-header">
                    {pageLoading ? (
                      <PageLoader />
                    ) : documentsDetails ? (
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="contact-tab-view">
                            <div className="tab-pane active show" id="preview">
                              <div className="contact-activity">
                                {documentsDetails?.recordStatus ===
                                  "active" && (
                                  <div
                                    className="d-flex justify-content-end"
                                    onClick={() =>
                                      onUpdatedocumentType(documentsDetails)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <i className="ti ti-edit text-blue p-1"></i>{" "}
                                    Edit
                                  </div>
                                )}
                              </div>
                              <div className="col-xl-12">
                                <div className="row details-row">
                                  <div className="col-md-6 col-head fw-bold heading-details">
                                    Employee
                                  </div>
                                  <div className="col-md-6 ">
                                    {documentsDetails?.user?.empName}
                                  </div>

                                  <div className="col-md-6 col-head fw-bold">
                                    Family
                                  </div>
                                  <div className="col-md-6 ">
                                    {documentsDetails?.familyMember?.name}
                                  </div>
                                  <div className="col-md-6 col-head fw-bold">
                                    Remainder Type
                                  </div>
                                  <div className="col-md-6 ">
                                    {documentsDetails?.documentType?.name}
                                  </div>
                                  <div className="col-md-6 col-head fw-bold">
                                    Reference Number
                                  </div>
                                  <div className="col-md-6 ">
                                    {documentsDetails?.documentNumber || "-"}
                                  </div>
                                  {/* <div className="col-md-6 col-head fw-bold">
                                    Issue Date
                                  </div>
                                  <div className="col-md-6 ">
                                    {moment(documentsDetails?.issueDate).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </div> */}
                                  <div className="col-md-6 col-head fw-bold">
                                    {documentsDetails?.documentType
                                      ?.isRecurring === "true"
                                      ? "Expiry Date"
                                      : "Remainder Date"}
                                  </div>
                                  <div className="col-md-6 ">
                                    {moment(
                                      documentsDetails?.expiryDate
                                    ).format("YYYY-MM-DD")}
                                  </div>
                                  <div className="col-md-6 col-head fw-bold">
                                    Description
                                  </div>
                                  <div className="col-md-6 ">
                                    {documentsDetails?.description || "-"}
                                  </div>
                                  <div className="col-md-6 col-head fw-bold">
                                    Documents
                                  </div>
                                  <div className="col-md-6">
                                    {documentsDetails?.document &&
                                    documentsDetails.document.length > 0
                                      ? documentsDetails.document.map(
                                          (doc, index) => (
                                            <div key={index}>
                                              <a
                                                href={fileBasicPath + doc.file}
                                                rel="noopener noreferrer"
                                              >
                                                {doc.name}
                                              </a>
                                            </div>
                                          )
                                        )
                                      : "-"}
                                  </div>

                                  <div className="col-md-6 col-head fw-bold">
                                    Status
                                  </div>
                                  <div className="col-md-6 ">
                                    {documentsDetails?.status}
                                  </div>
                                </div>
                                {documentsDetails?.customInfo &&
                                  Object.keys(documentsDetails.customInfo)
                                    .length > 0 && (
                                    <div className="row details-row">
                                      <ul className="">
                                        <li
                                          className="activity-wrap p-0"
                                          style={{ border: "none" }}
                                        >
                                          <div className="">
                                            <h5 className="mr-3">
                                              Custom Info
                                            </h5>
                                            <div className="row">
                                              <div className="col-md-6 col-head fw-bold heading-details">
                                                <h6>Label</h6>
                                              </div>
                                              <div className="col-md-6 col-head fw-bold heading-details">
                                                <h6>Value</h6>
                                              </div>
                                            </div>
                                            {Object.entries(
                                              documentsDetails.customInfo
                                            ).map(([key, value], index) => (
                                              <div className="row" key={index}>
                                                <div className="col-md-6">
                                                  <p>{key}</p>
                                                </div>
                                                <div className="col-md-6">
                                                  <p>{value}</p>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* /Tab Content */}
          </div>

          {/* Edit Remainder */}

          {documentsDetails && (
            <div
              className={
                documentModel ? "toggle-popup1 sidebar-popup" : "toggle-popup1"
              }
            >
              <div className="sidebar-layout">
                <div className="sidebar-header">
                  <h4>Edit Remainder</h4>
                  <span
                    className="sidebar-close1 toggle-btn"
                    onClick={() => setDocumentModel(false)}
                  >
                    <i className="ti ti-x" />
                  </span>
                </div>
                <div className="toggle-body">
                  <div className="pro-create">
                    <Formik
                      initialValues={{
                        userId: documentsDetails?.user
                          ? {
                              ...documentsDetails?.user,
                              label: documentsDetails?.user?.empName,
                              value: documentsDetails?.userId,
                            }
                          : "",
                        familyId: documentsDetails?.familyMember
                          ? {
                              ...documentsDetails?.familyMember,
                              label: documentsDetails?.familyMember?.name,
                              value: documentsDetails?.familyMember?.publicId,
                            }
                          : "",
                        documentTypeId: documentsDetails?.documentType
                          ? {
                              ...documentsDetails?.documentType,
                              label: documentsDetails?.documentType?.name,
                              value: documentsDetails?.documentType?.publicId,
                            }
                          : "",
                        documentNumber: documentsDetails?.documentNumber || "",
                        // issueDate:
                        //   moment(documentsDetails?.issueDate).format(
                        //     "YYYY-MM-DD"
                        //   ) || "",
                        expiryDate:
                          moment(documentsDetails?.expiryDate).format(
                            "YYYY-MM-DD"
                          ) || "",
                        // renewDate:
                        //   moment(documentsDetails?.renewDate).format(
                        //     "YYYY-MM-DD"
                        //   ) || "",
                        document: documentsDetails?.document || [],
                        customInfo: documentsDetails?.customInfo || {},
                        description: documentsDetails?.description || "",
                      }}
                      enableReinitialize={true}
                      validationSchema={relationTypeSchema}
                      onSubmit={(values) => {
                        updateDocumentTypeMaster(values);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <Form className="form" onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label
                                  className="col-form-label"
                                  htmlFor="name"
                                >
                                  Employee
                                  <span className="text-danger">*</span>
                                </label>
                                <AsyncSelect
                                  id="userId"
                                  name="userId"
                                  placeholder="Select User Id"
                                  onChange={(e) => {
                                    setFieldValue("userId", e);
                                    if (e?.publicId) {
                                      getFamilyOptions(e.publicId);
                                    }
                                  }}
                                  value={values?.userId}
                                  defaultOptions={userOptions}
                                  className="react-select"
                                  loadOptions={onSearchUserDebounce}
                                  cacheOptions
                                />

                                <CustomErrorMessage
                                  name="userId"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label
                                  className="col-form-label"
                                  htmlFor="familyId"
                                >
                                  Family
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <AsyncSelect
                                  id="familyId"
                                  name="familyId"
                                  placeholder="Select Family Id"
                                  onChange={(e) => setFieldValue("familyId", e)}
                                  value={values?.familyId}
                                  defaultOptions={familyOptions}
                                  className="react-select"
                                  loadOptions={onSearchFamilyDebounce}
                                  cacheOptions
                                />
                                {/* <CustomErrorMessage
                                  name="familyId"
                                  errors={errors}
                                  touched={touched}
                                /> */}
                              </div>
                            </div>
                            <div className="col-md-6 mt-3">
                              <div className="form-group">
                                <label
                                  className="col-form-label"
                                  htmlFor="name"
                                >
                                  Remainder Type
                                  <span className="text-danger">*</span>
                                </label>
                                <AsyncSelect
                                  id="documentTypeId"
                                  name="documentTypeId"
                                  placeholder="Select Remainder Type"
                                  onChange={(e) => {
                                    setFieldValue("documentTypeId", e);
                                    if (e?.publicId) {
                                      setSelectedDocType(e);
                                    }
                                  }}
                                  value={values?.documentTypeId}
                                  defaultOptions={docTypeOptions}
                                  className="react-select"
                                  loadOptions={onSearchDocTypeDebounce}
                                  cacheOptions
                                />

                                <CustomErrorMessage
                                  name="documentTypeId"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mt-3">
                              <div className="form-group">
                                <label
                                  className="col-form-label"
                                  htmlFor="name"
                                >
                                  Reference Number
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="documentNumber"
                                  id="documentNumber"
                                  placeholder="Enter Reference Number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.documentNumber}
                                />

                                <CustomErrorMessage
                                  name="documentNumber"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                            {/* <div className="col-md-6 mt-3">
                              <div className="form-group">
                                <label
                                  className="col-form-label"
                                  htmlFor="name"
                                >
                                  Issue Date
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="issueDate"
                                  id="issueDate"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.issueDate}
                                />

                                <CustomErrorMessage
                                  name="issueDate"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div> */}
                            <div className="col-md-12 mt-3">
                              <div className="form-group">
                                <label
                                  className="col-form-label"
                                  htmlFor="name"
                                >
                                  {selectedDocType
                                    ? selectedDocType?.isRecurring === "true"
                                      ? "Expiry Date"
                                      : "Remainder Date"
                                    : documentsDetails?.documentType
                                        ?.isRecurring === "true"
                                    ? "Expiry Date"
                                    : "Remainder Date"}

                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="expiryDate"
                                  id="expiryDate"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.expiryDate}
                                />

                                <CustomErrorMessage
                                  name="expiryDate"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mt-3">
                              <div className="form-wrap">
                                <label
                                  className="col-form-label"
                                  htmlFor="description"
                                >
                                  Description
                                </label>
                                <textarea
                                  className="form-control"
                                  rows={5}
                                  placeholder="Enter Description"
                                  aria-label="With textarea"
                                  name="description"
                                  id="description"
                                  value={values.description}
                                  onChange={handleChange}
                                ></textarea>
                                <CustomErrorMessage
                                  name="description"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                            {/* <div className="form-group mt-3">
                              <label className="col-form-label" htmlFor="name">
                                Renew Date
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                name="renewDate"
                                id="renewDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.renewDate}
                              />

                              <CustomErrorMessage
                                name="renewDate"
                                errors={errors}
                                touched={touched}
                              />
                            </div> */}
                          </div>

                          <div className="col-12">
                            {values.documentTypeId &&
                              values.documentTypeId?.fields.length > 0 && (
                                <div className="d-flex align-items-center gap-5 mt-3">
                                  <p
                                    className="mb-0"
                                    style={{ fontWeight: 500 }}
                                  >
                                    Custom Info :
                                  </p>
                                </div>
                              )}
                            {values.documentTypeId &&
                              values.documentTypeId?.fields.map(
                                (field, index) => (
                                  <div className="row mt-3" key={index}>
                                    <div className="form-group col-5">
                                      <div className="form-control-wrap">
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={field.name}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                    {field?.type === "text" && (
                                      <div className="form-group col-5">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`customInfo.${field.name}`}
                                          placeholder={`Enter ${field.name}`}
                                          value={values.customInfo[field.name]}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                    )}
                                    {field?.type === "date" && (
                                      <div className="form-group col-5">
                                        <input
                                          type="date"
                                          className="form-control"
                                          name={`customInfo.${field.name}`}
                                          value={values.customInfo[field.name]}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                    )}
                                    {field?.type === "boolean" && (
                                      <div className="col-5">
                                        <select
                                          as="select"
                                          className="form-select"
                                          name={`customInfo.${field.name}`}
                                          value={values.customInfo[field.name]}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        >
                                          <option value="">
                                            Select {field.name}
                                          </option>
                                          <option value="yes">Yes</option>
                                          <option value="no">No</option>
                                        </select>
                                      </div>
                                    )}
                                  </div>
                                )
                              )}
                          </div>
                          <div className="d-flex align-items-center gap-5 mt-3">
                            <p className="mb-0" style={{ fontWeight: 500 }}>
                              Documents :
                            </p>

                            <div
                              className="grey-symbol-btn"
                              style={{
                                width: "30px",
                                height: "30px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                borderRadius: "8px",
                                background: "#e5f0ff",
                              }}
                            >
                              <img
                                src={plusIcon}
                                alt="add"
                                onClick={() => {
                                  let document = values?.document || [];
                                  document.push({
                                    file: "",
                                  });
                                  setFieldValue("document", document);
                                }}
                              />
                            </div>
                          </div>
                          <CustomErrorMessage
                            name="document"
                            errors={errors}
                            touched={touched}
                          />
                          <div className="col-12">
                            {values.document &&
                              values.document.length > 0 &&
                              values.document?.map((_, index) => {
                                return (
                                  <div className="row mt-3">
                                    <div class="form-group col-5">
                                      <div class="form-control-wrap">
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="reg_content_sub_heading"
                                          name={`document.${index}.name`}
                                          placeholder="Label"
                                          value={_.name}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                    </div>
                                    <div class="form-group col-6">
                                      <div class="form-control-wrap">
                                        <input
                                          type="file"
                                          className="form-control"
                                          placeholder="Upload File"
                                          id="reg_content_sub_content"
                                          name={`document.${index}.file`}
                                          value={_.document?.file}
                                          onChange={(e) =>
                                            setFieldValue(
                                              `document.${index}.file`,
                                              e.target.files[0]
                                            )
                                          }
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="grey-symbol-btn col-1"
                                      onClick={() => {
                                        let document = values?.document || [];

                                        document = document.filter(
                                          (each, i) => i !== index
                                        );

                                        setFieldValue("document", document);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={minusIcon} alt="remove" />
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          {/* <div className="col-md-12 mt-3">
                            <div className="form-wrap">
                              <div className="profile-upload">
                                <div className="profile-upload-img">
                                  <span>
                                    <i className="ti ti-photo" />
                                  </span>
                                  <ImageWithBasePath
                                    src="assets/img/profiles/avatar-20.jpg"
                                    alt="img"
                                    className="preview1"
                                  />
                                  <button
                                    type="button"
                                    className="profile-remove"
                                  >
                                    <i className="ti ti-x" />
                                  </button>
                                </div>
                                <div className="profile-upload-content">
                                  <label className="profile-upload-btn">
                                    <i className="ti ti-file-broken" /> Upload
                                    File
                                    <input
                                      name="files"
                                      type="file"
                                      multiple
                                      onChange={(e) => {
                                        setFieldValue(
                                          "document",
                                          e.target.files
                                        );
                                      }}
                                      accept="*"
                                    />
                                  </label>
                                  <p>JPG, GIF or PNG. Max size of 800K</p>
                                </div>
                              </div>
                              <CustomErrorMessage
                                name="document"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div> */}

                          <div className="submit-button text-end mt-3">
                            <Button
                              onClick={() => setDocumentModel(false)}
                              className="btn btn-light sidebar-close"
                            >
                              Cancel
                            </Button>
                            <Button className="btn btn-primary" type="submit">
                              {buttonLoading ? (
                                <ButtonLoading />
                              ) : (
                                "Save Changes"
                              )}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* /Edit Remainder */}
        </div>
      </div>
    </>
  );
};

export default DocumentDetails;
