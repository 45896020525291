import React, { useState, useEffect } from "react";
import ImageWithBasePath from "../../../assets/images/ImageWithBasicPath";
import "./profile.scss"

// import {
//   companyName,
//   initialSettings,
//   status,
//   priorityList,
//   socialMedia,
//   salestypelist,
//   optionssymbol,
//   tagInputValues,
//   duration,
//   project,
// } from "../contacts/selectoption";
// import Select from "react-select";
import { Link } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { contactData } from "../contacts/contactData";
import Table from "../../../assets/common/dataTable";
import { TagsInput } from "react-tag-input-component";
import { allRoutes } from "../../../assets/routes/allRoutes";
import DatePicker from "react-datepicker";
import CollapseHeader from "../../../assets/common/CollapseHeader";
import { SelectWithImage } from "../../../assets/common/selectWithImage";
import useTheme from "../../../hooks/useTheme";
import DefaultEditor from "react-simple-wysiwyg";
import { SelectWithImage2 } from "../../../assets/common/selectWithImage2";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import { notifySuccess, notifyWarn } from "../../../assets/elements/Toast";
import MainServices from "../../../services/MainServices";
import usePermission from "../../../hooks/usePermission";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import Button from "react-bootstrap/Button";
import * as Storage from "../../../store/LocalStorage";
import { useLocation, useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import TimingFunctions from "../../../assets/functions/TimingFunctions";

import {
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
  filtersToUrlParams,
} from "../../../assets/functions/filters";

const Profile = () => {
  const location = useLocation();
  const route = allRoutes;
  const [owner, setOwner] = useState(["Collab"]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDate1, setSelectedDate1] = useState(new Date());
  const [pageLoading, setPageLoading] = useState(0);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [filters, setFilters] = useState({});
  const [searchString, setSearchString] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [addEmployeeModal, setAddEmployeeModal] = useState(false);
  const [digitalSignatureModal, setDigitalSignatureModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [documentDesignations, setDocumentDesignations] = useState([]);
  const [accessRoles, setAccessRoles] = useState([]);
  const [documentDepartments, setDocumentDepartments] = useState([]);

  // console.log("documentDepartments",documentDepartments);
  

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };

  const { getEmployeeMasters,
    addEmployeeMaster,
    getDesignationMasters,
    getDepartmentMasters,
    getRoleMasters,
  } = MainServices();

  const { deBounce } = TimingFunctions();

  //url Params
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  const {
    activityTogglePopup,
    activityTogglePopupTwo,
    addTogglePopupTwo,
    setActivityTogglePopup,
    setActivityTogglePopupTwo,
    setAddTogglePopupTwo,
  } = useTheme();

  const dealsopen = [
    { value: "choose", label: "Choose" },
    { value: "collins", label: "Collins" },
    { value: "konopelski", label: "Konopelski" },
    { value: "adams", label: "Adams" },
    { value: "schumm", label: "Schumm" },
    { value: "wisozk", label: "Wisozk" },
  ];
  const activities = [
    { value: "choose", label: "Choose" },
    { value: "phoneCalls", label: "Phone Calls" },
    { value: "socialMedia", label: "Social Media" },
    { value: "referralSites", label: "Referral Sites" },
    { value: "webAnalytics", label: "Web Analytics" },
    { value: "previousPurchases", label: "Previous Purchases" },
  ];
  const industries = [
    { value: "choose", label: "Choose" },
    { value: "Retail Industry", label: "Retail Industry" },
    { value: "Banking", label: "Banking" },
    { value: "Hotels", label: "Hotels" },
    { value: "Financial Services", label: "Financial Services" },
    { value: "Insurance", label: "Insurance" },
  ];
  const languages = [
    { value: "Choose", label: "Choose" },
    { value: "English", label: "English" },
    { value: "Arabic", label: "Arabic" },
    { value: "Chinese", label: "Chinese" },
    { value: "Hindi", label: "Hindi" },
  ];
  const countries = [
    { value: "Choose", label: "Choose" },
    { value: "India", label: "India" },
    { value: "USA", label: "USA" },
    { value: "France", label: "France" },
    { value: "UAE", label: "UAE" },
  ];
  const [stars, setStars] = useState({});

  const initializeStarsState = () => {
    const starsState = {};
    contactData.forEach((item, index) => {
      starsState[index] = false;
    });
    setStars(starsState);
  };

  // Call initializeStarsState once when the component mounts
  useEffect(() => {
    initializeStarsState();
  }, []);
  const data = contactData;
  const handleStarToggle = (index) => {
    setStars((prevStars) => ({
      ...prevStars,
      [index]: !prevStars[index],
    }));
  };
  const columns = [
    {
      title: "",
      dataIndex: "",
      render: (text, record, index) => (
        <div
          className={`set-star rating-select ${stars[index] ? "filled" : ""}`}
          onClick={() => handleStarToggle(index)}
          key={index}
        >
          {/* <i className="fa fa-star"></i> */}
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "Phone",
      render: (text, record, index) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          <Link to={route.contactsDetails} className="avatar">
            <ImageWithBasePath
              className="avatar-img"
              src={record.customer_image}
              alt={text}
            />
          </Link>
          <Link
            to={route.contactsDetails}
            className="profile-split d-flex flex-column"
          >
            {record.customer_name} <span>{record.customer_no}</span>
          </Link>
        </div>
      ),
      sorter: (a, b) => a.phone.length - b.phone.length,
    },

    {
      title: "Phone",
      dataIndex: "phone",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },

    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },

    // {
    //   title: "Tag",
    //   dataIndex: "tag",
    //   render: (text, index) => (
    //     <div key={index}>
    //       {text === "Collab" && (
    //         <span className="badge badge-tag badge-success-light">{text}</span>
    //       )}
    //       {text === "Promotion" && (
    //         <span className="badge badge-tag badge-purple-light">{text}</span>
    //       )}
    //       {text === "Rated" && (
    //         <span className="badge badge-tag badge-warning-light">{text}</span>
    //       )}
    //       {text === "Paused" && (
    //         <span className="badge badge-pill badge-status bg-info">
    //           {text}
    //         </span>
    //       )}
    //       {text === "Running" && (
    //         <span className="badge badge-pill badge-status bg-green">
    //           {text}
    //         </span>
    //       )}
    //     </div>
    //   ),
    //   sorter: true,
    // },
    // {
    //   title: "Location",
    //   dataIndex: "location",
    //   sorter: (a, b) => a.location.length - b.location.length,
    // },

    // {
    //   title: "Rating",
    //   dataIndex: "rating",
    //   render: (text, record, index) => (
    //     <div className="set-star" key={index}>
    //       <i className="fa fa-star filled me-2" />
    //       {text}
    //     </div>
    //   ),
    //   sorter: (a, b) => a.rating.length - b.rating.length,
    // },

    // {
    //   title: "Owner",
    //   dataIndex: "owner",
    //   sorter: (a, b) => a.owner.length - b.owner.length,
    // },
    // {
    //   title: "Contact",
    //   dataIndex: "",
    //   render: (index) => (
    //     <div className="social-links d-flex align-items-center" key={index}>
    //       <li>
    //         <Link to="#">
    //           <i className="ti ti-mail me-2"></i>
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="#">
    //           <i className="ti ti-phone-check me-2"></i>
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="#">
    //           <i className="ti ti-message-circle-share me-2"></i>
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="#">
    //           <i className="ti ti-brand-skype me-2"></i>
    //         </Link>
    //       </li>
    //       <li>
    //         <Link to="#">
    //           <i className="ti ti-brand-facebook "></i>
    //         </Link>
    //       </li>
    //     </div>
    //   ),
    //   sorter: (a, b) => a.owner.length - b.owner.length,
    // },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, index) => (
        <div key={index}>
          {text === "Active" && (
            <span className="badge badge-pill badge-status bg-success">
              {text}
            </span>
          )}
          {text === "Inactive" && (
            <span className="badge badge-pill badge-status bg-danger">
              {text}
            </span>
          )}
        </div>
      ),
      sorter: (a, b) => a.owner.length - b.owner.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (index) => (
        <div className="dropdown table-action" key={index}>
          <Link
            to="#"
            className="action-icon"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <i className="fa fa-ellipsis-v"></i>
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{
              position: "absolute",
              inset: "0px auto auto 0px",
              margin: "0px",
              transform: "translate3d(-99.3333px, 35.3333px, 0px)",
            }}
            data-popper-placement="bottom-start"
          >
            <Link
              className="dropdown-item edit-popup"
              to="#"
              onClick={() => setActivityTogglePopupTwo(!activityTogglePopupTwo)}
            >
              <i className="ti ti-edit text-blue"></i> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete_contact"
            >
              <i className="ti ti-trash text-danger"></i> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    // setSelectedStatus(filtersObj?.status?.status || "");
    setFilters(filtersObj);
    setSearchString(filtersObj?.name?.name);

    setPagination({
      page: changedParams?.page || 1,
      limit: changedParams?.limit || 10,
    });

    getRolePermissionMastersByStatus({
      page: 1,
      limit: 50,
      filters,
    });

    getEmployeeByStatus({
      page: changedParams?.page || 1,
      limit: changedParams.limit || 10,
      filters,
    });
    // eslint-disable-next-line
  }, [changedParams.page, changedParams.limit, changedParams.filters]);

  useEffect(() => {
    getDesignations();
    getDepartments([
      { key: "status", eq: "approved" },
      { key: "recordStatus", eq: "active" },
    ]);
    // getSecurityGroups();
    // getDivisionMasterByStatus();
    // getIsWorkflowSlug();
    // eslint-disable-next-line
  }, []);

  //apis
  const getEmployeeByStatus = async ({ page, limit, filters }) => {
    setPageLoading(true);
    const details = {
      pageSize: limit || 10,
      pageNumber: page || 1,
      filters,
      sorting: [{ key: "id", direction: "ASC" }],
    };
    await axios
      .all([getEmployeeMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let total = resData?.totalCount || 0;
            setTotalEmployees(total);
            let employee = resData.data ? resData.data : [];
            employee = employee.map((_, index) => {
              return {
                ..._,
                sNo: limit * (page - 1) + index + 1,
              };
            });
            setEmployees(employee);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Branches");
      });
  };

  const addEmployee = async (values) => {
    // const roles=values.roleId.map(_=>_.publicId)
    const securityGroups =
      values.securityGroupId &&
      values.securityGroupId.length > 0 &&
      values.securityGroupId.map((_) => _.publicId);
    let workflowInfo = values?.workflows || [];
    let workflowFlag = false;
    workflowInfo =
      workflowInfo.length > 0
        ? workflowInfo.map((each) => ({
            ...each,
            users: each?.users?.map((user) => {
              workflowFlag = true;
              return {
                value: user?.publicId,
              };
            }),
            ...(each?.no_of_approvals
              ? { no_of_approvals: each?.no_of_approvals }
              : {
                  no_of_approvals: each?.users && each?.users.length,
                }),
          }))
        : [];

    setButtonLoading(true);

    const details = {
      empName: values?.firstName + ' ' + values?.lastName,
      empEmail: values?.email,
      empContact: values?.contactNumber,
      empCode: values?.employeeCode,
      userName: values?.email,
      // empFirstLoginStatus: "true",
      deptId: values?.discipline?.publicId,
      desgId: values?.designation?.publicId,
      roleId: values?.role?.publicId,
      // branchId: values?.branchId?.publicId,
      // plantId: values?.plantId?.publicId,
      // divisionId: values?.divisionId?.publicId,
      groupId: securityGroups || [],
      // approver: values?.role === "approver" ? true : false,
      // reviewer: values?.role === "reviewer" ? true : false,

      joiningDate: values?.joiningDate,
      ...(values?.password && { password: values?.password }),
      status: "active",
    };
    if (values.userPassword) {
      details.userPassword = values.userPassword;
    } else {
      details.userPassword = values.password;
    }
    if (workflowFlag) {
      details.workflowInfo = workflowInfo;
    }

    await axios
      .all([addEmployeeMaster(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          console.log(resData);
          let message = resData?.message;
          if (resData.success) {
            getEmployeeByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters,
            });
            onAddEmployee(false);
            setDigitalSignatureModal(false);
            notifySuccess("Employee Master Added Successfully");
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in adding Employee Master");
      });
  };

  const getDesignations = async () => {
    const details = {
      status: "ACTIVE",
    };
    await axios
      .all([getDesignationMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let designations = resData?.data || [];
            designations = designations && designations.length
             ? designations.map((each) => {
              return {
                ...each,
                label: each?.desgName || "",
                value: each?.publicId || "",
              };
            }) : [];

            setDocumentDesignations(designations);
          } else {
            if (message) notifyWarn(message);
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in getting Designations");
      });
  };

  const getDepartments = async (filters, search) => {
    const details = {
      filters,
      // status: "active",
    };
    let results = await getDepartmentMasters(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            let departments = resData?.data;
            departments = departments.map((each) => {
              return {
                ...each,
                label: each?.deptName,
                value: each?.publicId,
              };
            });
            departments.unshift({ label: "Select Department", value: "" });
            !search && setDocumentDepartments(departments);
            return departments;
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in getting Departments");
      });
    return results;
  };

  const getRolePermissionMastersByStatus = async ({ page, limit, filters }) => {
    setPageLoading(true);
    const details = {
      pageSize: limit || 20,
      pageNumber: page || 1,
      filters,
    };
    await axios
      .all([getRoleMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let role = resData.data ? resData.data : [];
            role = role.map((_, index) => {
              return {
                ..._,
                label: _?.role,
                value: _?.publicId,
              };
            });
            setAccessRoles(role);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Roles");
      });
  };

  // functions

  const onAddEmployee = (selected) => {
    if (selected) {
      setSelectedEmployee(selected);
    } else {
      setSelectedEmployee("");
    }
    setAddEmployeeModal(!addEmployeeModal);
  };

  const onSearchdDeptDebounce = (inputValue, callback) => {
    deBounce(() => onSearchdDept(inputValue, callback), 1000);
  };

  const onSearchdDept = async (inputValue, callback) => {
    if (!inputValue) {
      callback(documentDepartments);
      return;
    }

    let filter = [
      {
        key: "deptName",
        iLike: inputValue,
      },
      { key: "status", eq: "approved" },
      { key: "recordStatus", eq: "active" },
    ];
    let options = await getDepartments(filter, true);
    callback(options);
  };
  return (
    <div>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h4 className="page-title">Profile</h4>
                  </div>
                  <div className="col-4 text-end"></div>
                </div>
              </div>
              {/* /Page Header */}
              <div className="card main-card">
                <div className="card-body">
                  {/* Search */}
                  <div className="search-section">
                    <div className="row">
                      <div className="col-md-7 col-sm-8"></div>
                      <div className="pro-create">
                        <Formik
                          initialValues={{
                            firstName: "",
                            lastName: "",
                            contactNumber: "",
                            email: "",
                            employeeCode: "",
                            userName: "",
                            joiningDate: null,
                            discipline: null,
                            designation: null,
                            role: null,
                            reportingManager: "",
                            password: "",
                            confirmPassword: "",
                            visibility: "public",
                            status: "active",
                          }}
                          // validationSchema={validationSchema}
                          onSubmit={(values) => {
                            addEmployee(values);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <Form>
                              <div className="accordion-lists" id="list-accord">
                                {/* Basic Info */}
                                <div className="user-accordion-item">
                                  <Link
                                    to="#"
                                    className="accordion-wrap"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#basic"
                                  >
                                    <span>
                                      <i className="ti ti-user-plus" />
                                    </span>
                                    Basic Info
                                  </Link>
                                  <div
                                    className="accordion-collapse collapse show"
                                    id="basic"
                                    data-bs-parent="#list-accord"
                                  >
                                    <div className="content-collapse">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="form-wrap">
                                            <div className="profile-upload">
                                              <div className="profile-upload-img">
                                                <span>
                                                  <i className="ti ti-photo" />
                                                </span>
                                                <ImageWithBasePath
                                                  src="assets/img/profiles/avatar-20.jpg"
                                                  alt="img"
                                                  className="preview1"
                                                />
                                                <button
                                                  type="button"
                                                  className="profile-remove"
                                                >
                                                  <i className="ti ti-x" />
                                                </button>
                                              </div>
                                              <div className="profile-upload-content">
                                                <label className="profile-upload-btn">
                                                  <i className="ti ti-file-broken" />{" "}
                                                  Upload File
                                                  <input
                                                    type="file"
                                                    className="input-img"
                                                  />
                                                </label>
                                                <p>
                                                  JPG, GIF or PNG. Max size of
                                                  800K
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-wrap">
                                            <label className="col-form-label">
                                              First Name{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              type="text"
                                              name="firstName"
                                              className="inputsize w-100"
                                            />
                                            <CustomErrorMessage
                                              name="firstName"
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div className="form-wrap">
                                            <label className="col-form-label">
                                              Last Name{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              type="text"
                                              name="lastName"
                                              className="inputsize w-100"
                                            />
                                            <CustomErrorMessage
                                              name="lastName"
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-wrap">
                                            <label className="col-form-label">
                                              Contact Number{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              type="text"
                                              name="contactNumber"
                                              className="inputsize w-100"
                                            />
                                            <CustomErrorMessage
                                              name="contactNumber"
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-wrap">
                                            <label className="col-form-label">
                                              Email{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              type="email"
                                              name="email"
                                              className="inputsize w-100"
                                            />
                                            <CustomErrorMessage
                                              name="email"
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-wrap">
                                            <label className="col-form-label">
                                              Employee Code{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              type="text"
                                              name="employeeCode"
                                              className="inputsize w-100"
                                            />
                                            <CustomErrorMessage
                                              name="employeeCode"
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-wrap">
                                            <label className="col-form-label">
                                              Joining Date
                                            </label>
                                            <div className="icon-form-end">
                                              <span className="form-icon">
                                                <i className="ti ti-calendar-event" />
                                              </span>
                                              <DatePicker
                                                className="inputsize w-100 datetimepicker deals-details"
                                                selected={selectedDate}
                                                onChange={(date) => {
                                                  setFieldValue(
                                                    "joiningDate",
                                                    date
                                                  );
                                                  handleDateChange(date);
                                                }}
                                                dateFormat="dd-MM-yyyy"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-wrap">
                                            <label className="col-form-label">
                                              Discipline{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <AsyncSelect
                                              id="discipline"
                                              name="discipline"
                                              placeholder="Select Department"
                                              onChange={(option) => {
                                                // console.log("setted_discipline", option);
                                                setFieldValue(
                                                  "discipline",
                                                  option
                                                );
                                              }}
                                              value={values?.discipline}
                                              defaultOptions={
                                                documentDepartments
                                              }
                                              className="react-select"
                                              loadOptions={
                                                onSearchdDeptDebounce
                                              }
                                              cacheOptions
                                            />
                                            {/* <Select
                                    classNamePrefix="react-select"
                                    options={documentDepartments}
                                    onChange={(option) =>
                                      setFieldValue("discipline", option)
                                    }
                                    placeholder="Select an option"
                                  /> */}
                                            <CustomErrorMessage
                                              name="discipline"
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-wrap">
                                            <label className="col-form-label">
                                              Designation{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <AsyncSelect
                                              id="designation"
                                              name="designation"
                                              placeholder="Select Department"
                                              onChange={(option) =>
                                                setFieldValue(
                                                  "designation",
                                                  option
                                                )
                                              }
                                              value={values?.designation}
                                              defaultOptions={
                                                documentDesignations
                                              }
                                              className="react-select"
                                              // loadOptions={onSearchdDeptDebounce}
                                              cacheOptions
                                            />
                                            {/* <Select
                                    classNamePrefix="react-select"
                                    options={documentDesignations}
                                    onChange={(option) =>
                                      setFieldValue("designation", option?.publicId)
                                    }
                                    placeholder="Select an option"
                                  /> */}
                                            <CustomErrorMessage
                                              name="designation"
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-wrap">
                                            <label className="col-form-label">
                                              Role{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <AsyncSelect
                                              id="role"
                                              name="role"
                                              placeholder="Select Department"
                                              onChange={(option) =>
                                                setFieldValue("role", option)
                                              }
                                              value={values?.role}
                                              defaultOptions={accessRoles}
                                              className="react-select"
                                              // loadOptions={onSearchdDeptDebounce}
                                              cacheOptions
                                            />
                                            {/* <Select
                                    classNamePrefix="react-select"
                                    options={accessRoles}
                                    onChange={(option) =>
                                      setFieldValue("discipline", option)
                                    }
                                    placeholder="Select an option"
                                  /> */}
                                            <CustomErrorMessage
                                              name="role"
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                <div className="form-wrap">
                                  <label className="col-form-label">
                                    User Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    name="username"
                                    className="form-control"
                                  />
                                  <CustomErrorMessage
                                    name="username"
                                    errors={errors}
                                    touched={touched}
                                  />
                                </div>
                              </div> */}
                                        <div className="col-md-6">
                                          <div className="form-wrap">
                                            <label className="col-form-label">
                                              Reporting Manager
                                            </label>
                                            <SelectWithImage />
                                          </div>
                                        </div>
                                        {/* Other form fields */}
                                        <div className="col-md-6">
                                          <div className="form-wrap">
                                            <label className="col-form-label">
                                              Password{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              type="password"
                                              name="password"
                                              className="inputsize w-100"
                                            />
                                            <CustomErrorMessage
                                              name="password"
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-wrap">
                                            <label className="col-form-label">
                                              Confirm Password{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              type="password"
                                              name="confirmPassword"
                                              className="inputsize w-100"
                                            />
                                            <CustomErrorMessage
                                              name="confirmPassword"
                                              errors={errors}
                                              touched={touched}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* Basic Info */}
                                {/* Access */}
                                <div className="user-accordion-item">
                                  <Link
                                    to="#"
                                    className="accordion-wrap collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#access"
                                  >
                                    <span>
                                      <i className="ti ti-accessible" />
                                    </span>
                                    Access
                                  </Link>
                                  <div
                                    className="accordion-collapse collapse"
                                    id="access"
                                    data-bs-parent="#list-accord"
                                  >
                                    <div className="content-collapse">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="radio-wrap form-wrap">
                                            <label className="col-form-label">
                                              Visibility
                                            </label>
                                            <div className="d-flex flex-wrap">
                                              <div className="radio-btn">
                                                <input
                                                  type="radio"
                                                  className="status-radio"
                                                  id="public"
                                                  name="visible"
                                                />
                                                <label htmlFor="public">
                                                  Public
                                                </label>
                                              </div>
                                              <div className="radio-btn">
                                                <input
                                                  type="radio"
                                                  className="status-radio"
                                                  id="private"
                                                  name="visible"
                                                />
                                                <label htmlFor="private">
                                                  Private
                                                </label>
                                              </div>
                                              <div
                                                className="radio-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#access_view"
                                              >
                                                <input
                                                  type="radio"
                                                  className="status-radio"
                                                  id="people"
                                                  name="visible"
                                                />
                                                <label htmlFor="people">
                                                  Select People
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="radio-wrap">
                                            <label className="col-form-label">
                                              Status
                                            </label>
                                            <div className="d-flex flex-wrap">
                                              <div className="radio-btn">
                                                <input
                                                  type="radio"
                                                  className="status-radio"
                                                  id="active"
                                                  name="status"
                                                  defaultChecked
                                                />
                                                <label htmlFor="active">
                                                  Active
                                                </label>
                                              </div>
                                              <div className="radio-btn">
                                                <input
                                                  type="radio"
                                                  className="status-radio"
                                                  id="inactive"
                                                  name="status"
                                                />
                                                <label htmlFor="inactive">
                                                  Inactive
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* /Access */}

                                <div className="col-12">
                                  <div className="form-wrap">
                                    <Button
                                      type="submit"
                                      className="btn btn-primary"
                                      disabled={isSubmitting}
                                    >
                                      {isSubmitting ? (
                                        <ButtonLoading />
                                      ) : (
                                        "Add Employee"
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>

                      <div
                        className="modal custom-modal fade"
                        id="delete_contact"
                        role="dialog"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header border-0 m-0 justify-content-end">
                              <button
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              >
                                <i className="ti ti-x" />
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="success-message text-center">
                                <div className="success-popup-icon">
                                  <i className="ti ti-trash-x" />
                                </div>
                                <h3>Remove Employee?</h3>
                                <p className="del-info">
                                  Are you sure you want to remove contact you
                                  selected.
                                </p>
                                <div className="col-lg-12 text-center modal-btn">
                                  <Link
                                    to="#"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                  >
                                    Cancel
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-danger"
                                    data-bs-dismiss="modal"
                                  >
                                    Yes, Delete it
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* /Search */}
                  {/* Filter */}

                  {/* /Filter */}
                  {/* Contact List */}

                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="datatable-length" />
                    </div>
                    <div className="col-md-6">
                      <div className="datatable-paginate" />
                    </div>
                  </div>
                  {/* /Contact List */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* Add Contact */}

      {/* /Add Contact */}
      {/* Edit Contact */}

      {/* /Edit Contact */}
      {/* Add New Deals */}

      {/* /Add New Deals */}
      {/* Delete Contact */}

      {/* /Delete Contact */}
      {/* Create Contact */}
      {/* <div
        className="modal custom-modal fade"
        id="create_contact"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 m-0 justify-content-end">
              <button
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon bg-light-blue">
                  <i className="ti ti-user-plus" />
                </div>
                <h3>Contact Created Successfully!!!</h3>
                <p>View the details of contact, created</p>
                <div className="col-lg-12 text-center modal-btn">
                  <Link
                    to="#"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link to={route.contactsDetails} className="btn btn-primary">
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* /Create Contact */}
      {/* Add New View */}
      {/* <div className="modal custom-modal fade" id="save_view" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add New View</h5>
              <button
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-wrap">
                  <label className="col-form-label">View Name</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="modal-btn text-end">
                  <Link
                    to="#"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link to="#" className="btn btn-danger">
                    Save
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {/* /Add New View */}
      {/* Access */}
      {/* <div className="modal custom-modal fade" id="access_view" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Access For</h5>
              <button
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-wrap icon-form">
                  <span className="form-icon">
                    <i className="ti ti-search" />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                </div>
                <div className="access-wrap">
                  <ul>
                    <li className="select-people-checkbox">
                      <label className="checkboxs">
                        <input type="checkbox" />
                        <span className="checkmarks" />
                        <span className="people-profile">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-19.jpg"
                            alt=""
                          />
                          <Link to="#">Darlee Robertson</Link>
                        </span>
                      </label>
                    </li>
                    <li className="select-people-checkbox">
                      <label className="checkboxs">
                        <input type="checkbox" />
                        <span className="checkmarks" />
                        <span className="people-profile">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-20.jpg"
                            alt=""
                          />
                          <Link to="#">Sharon Roy</Link>
                        </span>
                      </label>
                    </li>
                    <li className="select-people-checkbox">
                      <label className="checkboxs">
                        <input type="checkbox" />
                        <span className="checkmarks" />
                        <span className="people-profile">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-21.jpg"
                            alt=""
                          />
                          <Link to="#">Vaughan</Link>
                        </span>
                      </label>
                    </li>
                    <li className="select-people-checkbox">
                      <label className="checkboxs">
                        <input type="checkbox" />
                        <span className="checkmarks" />
                        <span className="people-profile">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-01.jpg"
                            alt=""
                          />
                          <Link to="#">Jessica</Link>
                        </span>
                      </label>
                    </li>
                    <li className="select-people-checkbox">
                      <label className="checkboxs">
                        <input type="checkbox" />
                        <span className="checkmarks" />
                        <span className="people-profile">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-16.jpg"
                            alt=""
                          />
                          <Link to="#">Carol Thomas</Link>
                        </span>
                      </label>
                    </li>
                    <li className="select-people-checkbox">
                      <label className="checkboxs">
                        <input type="checkbox" />
                        <span className="checkmarks" />
                        <span className="people-profile">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-22.jpg"
                            alt=""
                          />
                          <Link to="#">Dawn Mercha</Link>
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="modal-btn text-end">
                  <Link
                    to="#"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Confirm
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {/* /Access */}
    </div>
  );
}

export default Profile