import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { allRoutes } from "../../../../assets/routes/allRoutes";
import CollapseHeader from "../../../../assets/common/CollapseHeader";
import MainServices from "../../../../services/MainServices";
import PageLoader from "../../../../assets/loaders/PageLoading";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import useTheme from "../../../../hooks/useTheme";
import { Formik } from "formik";
import { notifySuccess, notifyWarn } from "../../../../assets/elements/Toast";
import { Form, Button } from "react-bootstrap";
import CustomErrorMessage from "../../../../assets/elements/ErrorMessage";
import Select from "react-select";
import plusIcon from "../../../../assets/images/icons/add-icon.svg";
import minusIcon from "../../../../assets/images/icons/minus-icon.svg";
import ButtonLoading from "../../../../assets/loaders/ButtonLoading";
import "./DocumentType.scss";
const DocumentTypeDetails = () => {
  const route = allRoutes;
  const [buttonLoading, setButtonLoading] = useState(0);
  const [documentType, setDocumentType] = useState({});
  const [pageLoading, setPageLoading] = useState(0);
  const { getDocumentDetails, updateDocumentType } = MainServices();
  const location = useLocation();
  const publicId = new URLSearchParams(location.search).get("publicId");
  const [editInitiated, setEditInitiated] = useState(false);
  const edit = new URLSearchParams(location.search).get("edit");
  const [documentModel, setDocumentModel] = useState(false);

  // const remainderOptions = [
  //   { value: "daily", label: "Daily" },
  //   { value: "monthly", label: "Monthly" },
  //   { value: "yearly", label: "Yearly" },
  // ];
  const remainderTypeOptions = [
    { value: "days", label: "Days" },
    { value: "weeks", label: "Weeks" },
    { value: "months", label: "Months" },
    { value: "years", label: "Years" },
  ];
  const typeOptions = [
    { value: "text", label: "Text" },
    { value: "date", label: "Date" },
    { value: "boolean", label: "Boolean" },
  ];

  const relationTypeSchema = Yup.object().shape({
    name: Yup.string()
      .transform((value) => value.trim())
      .required("Name is Required"),
    description: Yup.string()
      .transform((value) => value.trim())
      .required("Description is Required"),
    code: Yup.string()
      .transform((value) => value.trim())
      .required("Code is Required"),
    remainderType: Yup.string()
      .transform((value) => value.trim())
      .required("Remainder Type is Required"),
    remainderPeriod: Yup.string()
      .transform((value) => value.trim())
      .required("Remainder Period is Required"),
  });

  useEffect(() => {
    if (publicId) {
      getDocDetailsById();
    }
  }, [publicId]);

  const getDocDetailsById = async () => {
    setPageLoading(true);
    const details = {};
    await axios
      .all([getDocumentDetails(details, publicId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let documentTypeDetails = resData.data ? resData?.data : {};
            setDocumentType(documentTypeDetails);
            if (edit === "true" && !editInitiated) {
              onUpdatedocumentType(documentTypeDetails);
              setEditInitiated(true);
            }
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Remainder Type Details");
      });
  };

  const updateDocumentTypeMaster = async (values) => {
    setButtonLoading(true);
    const details = {
      name: values?.name,
      description: values?.description,
      code: values?.code,
      remainderType: values?.remainderType,
      remainderPeriod: values?.remainderPeriod,
      fields: JSON.stringify(values.fields),
      isRecurring: values?.isRecurring,
    };
    await axios
      .all([
        updateDocumentType(
          details,
          documentType?.publicId,
          documentType?.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getDocDetailsById();
            setDocumentModel(false);
            notifySuccess("Remainder Type Updated Successfully");
            setButtonLoading(false);
          } else {
            notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in update Remainder Type");
      });
  };

  //functions
  const onUpdatedocumentType = (selected) => {
    if (selected) {
      setDocumentType(selected);
    }
    setDocumentModel(!documentModel);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-10">
                    <h4 className="page-title">Remainder Type Details</h4>
                  </div>
                  <div className="col-sm-2 text-sm-start">
                    <div className="head-icons">
                      <CollapseHeader />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {pageLoading ? (
            <PageLoader />
          ) : documentType ? (
            <div className="row">
              <div className="row handle" style={{ padding: "5px 20px" }}>
                <div className="col-6">
                  <div className="contact-head">
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <ul className="contact-breadcrumb">
                          <li>
                            <Link to={route.documentType}>
                              <i
                                className="ti ti-arrow-narrow-left"
                                style={{ fontSize: "25px" }}
                              />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="tab-pane active show" id="activities">
                    <div className="contact-activity">
                      {documentType?.recordStatus === "active" && (
                        <div
                          className="d-flex justify-content-end"
                          to="#"
                          onClick={() => onUpdatedocumentType(documentType)}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="ti ti-edit text-blue p-1"></i> Edit
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-12">
                <div className="row details-row">
                  <div className="col-md-6 col-head fw-bold heading-details">
                    Name
                  </div>
                  <div className="col-md-6 ">{documentType?.name}</div>

                  <div className="col-md-6 col-head fw-bold">Description</div>
                  <div className="col-md-6 ">{documentType?.description}</div>
                  <div className="col-md-6 col-head fw-bold">code</div>
                  <div className="col-md-6 ">{documentType?.code}</div>
                  <div className="col-md-6 col-head fw-bold">
                    Remainder Type
                  </div>
                  <div className="col-md-6 ">{documentType?.remainderType}</div>
                  <div className="col-md-6 col-head fw-bold">
                    Remainder Period
                  </div>
                  <div className="col-md-6 ">
                    {documentType?.remainderPeriod}
                  </div>
                  <div className="col-md-6 col-head fw-bold">Status</div>
                  <div className="col-md-6 ">{documentType?.status}</div>
                </div>
                {documentType?.fields && documentType?.fields.length > 0 && (
                  <div className="row details-row">
                    <ul className="">
                      <li
                        className="activity-wrap p-0 "
                        style={{ border: "none" }}
                      >
                        <div className="">
                          <h5 className="mr-3">Fields</h5>
                          <div className="row">
                            <div className="col-md-6 col-head fw-bold heading-details">
                              <h6>Name</h6>
                            </div>
                            <div className="col-md-6 col-head fw-bold heading-details">
                              <h6>Type</h6>
                            </div>
                          </div>
                          {documentType.fields.map((field, index) => (
                            <div className="row" key={index}>
                              <div className="col-md-6">
                                <p>{field.name}</p>
                              </div>
                              <div className="col-md-6">
                                <p>{field.type}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* Edit Document */}

          {documentType && (
            <div
              className={
                documentModel ? "toggle-popup1 sidebar-popup" : "toggle-popup1"
              }
            >
              <div className="sidebar-layout">
                <div className="sidebar-header">
                  <h4>Edit Remainder Type</h4>
                  <span
                    className="sidebar-close1 toggle-btn"
                    onClick={() => setDocumentModel(false)}
                  >
                    <i className="ti ti-x" />
                  </span>
                </div>
                <div className="toggle-body">
                  <div className="pro-create">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        name: documentType?.name || "",
                        description: documentType?.description || "",
                        code: documentType?.code || "",
                        remainderType: documentType?.remainderType || "",
                        remainderPeriod: documentType?.remainderPeriod || "",
                        fields: documentType?.fields || [],
                        isRecurring: documentType?.isRecurring || "false",
                      }}
                      validationSchema={relationTypeSchema}
                      onSubmit={(values) => {
                        updateDocumentTypeMaster(values);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <Form className="form" onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-wrap">
                                <label
                                  className="col-form-label"
                                  htmlFor="name"
                                >
                                  Name
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  id="name"
                                  placeholder="Enter Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.name}
                                />

                                <CustomErrorMessage
                                  name="name"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-wrap">
                                <label
                                  className="col-form-label"
                                  htmlFor="description"
                                >
                                  Description
                                  <span className="text-danger">*</span>
                                </label>
                                <textarea
                                  className="form-control"
                                  rows={5}
                                  placeholder="Enter Description"
                                  aria-label="With textarea"
                                  name="description"
                                  id="description"
                                  value={values.description}
                                  onChange={handleChange}
                                ></textarea>
                                <CustomErrorMessage
                                  name="description"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-wrap">
                                <label
                                  className="col-form-label"
                                  htmlFor="name"
                                >
                                  Code
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="code"
                                  id="code"
                                  placeholder="Enter Code"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.code}
                                />

                                <CustomErrorMessage
                                  name="code"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-wrap">
                                <label className="col-form-label">
                                  Remainder Type
                                  <span className="text-danger">*</span>
                                </label>

                                <Select
                                  className="select"
                                  classNamePrefix="react-select"
                                  options={remainderTypeOptions}
                                  placeholder="Select Remainder Type"
                                  value={remainderTypeOptions.find(
                                    (option) =>
                                      option.value === values.remainderType
                                  )}
                                  onChange={(option) => {
                                    setFieldValue(
                                      "remainderType",
                                      option.value
                                    );
                                  }}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-wrap">
                                <label
                                  className="col-form-label"
                                  htmlFor="name"
                                >
                                  Remainder Period
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="remainderPeriod"
                                  id="remainderPeriod"
                                  placeholder="Enter Remainder Period"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.remainderPeriod}
                                />

                                <CustomErrorMessage
                                  name="remainderPeriod"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                            <div className="form-group mb-4">
                              <label htmlFor="approver" className="ms-2">
                                Is Recuring
                              </label>
                              <label className="switch">
                                <input
                                 type="checkbox"
                                 id="isRecurring"
                                 name="isRecurring"
                                 checked={values.isRecurring === "true"}
                                 onChange={(e) =>
                                   setFieldValue(
                                     "isRecurring",
                                     e.target.checked ? "true" : "false"
                                   )
                                 }
                                 onBlur={handleBlur}
                                />
                                <span className="slider round"></span>
                              </label>
                            </div>
                            <div className="d-flex align-items-center gap-5">
                              <p className="mb-0" style={{ fontWeight: 500 }}>
                                Fields :
                              </p>

                              <div
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  borderRadius: "8px",
                                  background: "#e5f0ff",
                                }}
                              >
                                <img
                                  src={plusIcon}
                                  alt="add"
                                  onClick={() => {
                                    let fields = values?.fields || [];
                                    fields.push({
                                      name: "",
                                      type: "",
                                    });
                                    setFieldValue("fields", fields);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-12">
                              {values.fields &&
                                values.fields.length > 0 &&
                                values.fields.map((_, index) => {
                                  return (
                                    <div className="row mt-3" key={index}>
                                      <div className="form-group col-5">
                                        <div className="form-control-wrap">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name={`fields.${index}.name`}
                                            placeholder="Name"
                                            value={_.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                        </div>
                                      </div>
                                      <div className="form-group col-5">
                                        <div className="form-control-wrap">
                                          <Select
                                            className="select"
                                            classNamePrefix="react-select"
                                            name={`fields.${index}.type`}
                                            options={typeOptions}
                                            placeholder="Select Type"
                                            value={typeOptions.find(
                                              (option) =>
                                                option.value === _.type
                                            )}
                                            onChange={(e) =>
                                              setFieldValue(
                                                `fields.${index}.type`,
                                                e.value
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div
                                        className="grey-symbol-btn col-2"
                                        onClick={() => {
                                          let fields = values?.fields || [];
                                          fields = fields.filter(
                                            (each, i) => i !== index
                                          );
                                          setFieldValue("fields", fields);
                                        }}
                                      >
                                        <img src={minusIcon} alt="remove" />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          <div className="submit-button text-end mt-3">
                            <Button
                              className="btn btn-light sidebar-close"
                              onClick={() => setDocumentModel(false)}
                            >
                              Cancel
                            </Button>
                            <Button className="btn btn-primary" type="submit">
                              {buttonLoading ? (
                                <ButtonLoading />
                              ) : (
                                "Save Changes"
                              )}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* /Edit Document */}
        </div>
      </div>
    </>
  );
};

export default DocumentTypeDetails;
